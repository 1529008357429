import React from "react";
import { useHistory } from "react-router-dom";
import { Field } from "formik";
import * as yup from "yup";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useTranslation } from 'react-i18next';
import { useApiGet } from "../../../../utils/useApi";
import Select from "../../controls/Select";

const mapUserRolesToOptions = ({ data, error, loading }) => {
  const emptyOption = { label: "", value: null };
  return !error && !loading && data
    ? [
        ...data.map(r => ({
          label: r.match(/[A-Z][a-z]+/g).join(" "),
          value: r
        }))
      ]
    : [emptyOption];
};

export const initialValues = {
  role: ""
};

export const validationSchema = {
  role: yup
    .string()
    .label("Role")
    .required()
    .typeError("Role must be a string")
};

const RoleField = props => {
  const history = useHistory();
  const [userRoles] = useApiGet("UserRoles", null, history);
  const { loading } = userRoles;
  const { t } = useTranslation();

  return (
    <div>
      <Field
        component={Select}
        label={t("Role")}
        name="role"
        options={mapUserRolesToOptions(userRoles)}
        variant="standard"
      />
      {loading && <LinearProgress />}
    </div>
  );
};

export default RoleField;
