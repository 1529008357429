import * as yup from "yup";

const createMultiplicityValidator = (multiplicityCount, t) => {
  let validator;

  if (multiplicityCount === 1) {
    // if count 1 -> expect single option
    validator = yup.object().shape({
      id: yup.number().required(),
      name: yup.string().required()
    })
    .typeError("Please choose a value")
    .required("Please choose a value")
  } else if (multiplicityCount > 1) {
    // if count > 1 -> array with X options
    validator = yup.array()
      .min(multiplicityCount, `${t("Select at least")} ${multiplicityCount}`)
      .max(multiplicityCount, `${t("Select at most")} ${multiplicityCount}`)
      .of(yup.object().shape({
        id: yup.number().required(),
        name: yup.string().required()
      }))
      .typeError("Please choose a value")
      .required("Please choose a value")
  } else if (multiplicityCount === 0) {
    // if count = 0 -> array with min 1
    validator = yup.array()
      .min(1, `${t("Select at least")} 1`)
      .of(yup.object().shape({
        id: yup.number().required(),
        name: yup.string().required()
      }))
      .typeError("Please choose a value")
      .required("Please choose a value")
  }

  return validator;
}

export const createYupSchema = (config, t) => {
  let schema = {};
  const { multiplicityCountPrimaryType: primaryCount, categories } = config;

  const primaryValidator = createMultiplicityValidator(primaryCount, t);
  schema["primary"] = primaryValidator;

  (categories || []).forEach(category => {
    const { name, multiplicityCount: count } = category;
    const validator = createMultiplicityValidator(count, t);
    schema[name] = validator;
  });

  return schema;
}
