import React, { useContext } from "react";
import classNames from "classnames";
import { useApiGet } from "../utils/useApi";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AzureMediaPlayer from "../shared/AzureMediaPlayer";
import Spinner from "../shared/Spinner";
import Typography from "@material-ui/core/Typography";
import styles from "./Tutorials.module.css";
import { ResponsiveContext } from "../shared/layout/ResponsiveContext";
import driverReadyLogo from "../assets/images/driver-ready-logo.png";
import pilotReadyLogo from "../assets/images/pilot-ready-logo.png";
import surgeonReadyLogo from "../assets/images/surgeon-ready-logo.png";
import systemReadyLogo from "../assets/images/system-ready-logo.png";

export default props => {
  const history = useHistory();
  const { isMobile } = useContext(ResponsiveContext);
  const [videos] = useApiGet(`Tutorials/StreamingVideos`, null, history);
  const { t } = useTranslation();
  let customerVariantion = props.customerVariantion;

  const isValidVariant = ["Pilot", "Automotive", "Surgeon", "System"].includes(
    customerVariantion
  );
  if (!isValidVariant) customerVariantion = null;

  return (
    <div
      className={classNames(styles.tutorial_videos, {
        [styles.tutorial_videos_mobile]: isMobile
      })}
    >
      <Typography variant="h4" className={styles.tutorials_title}>
        {t("Tutorials")}
      </Typography>

      <Typography variant="body1">
        {t(
          "Learn the basics and watch tutorials to quickly get started using the client UI and the secure cloud-based platform."
        )}
      </Typography>

      <div
        className={
          isValidVariant ? styles.logos_grid_single : styles.logos_grid
        }
      >
        {(customerVariantion === "Pilot" || !customerVariantion) && (
          <div>
            <img
              src={pilotReadyLogo}
              alt="Pilot Ready Logo"
              className={styles.logo}
            />
            <Typography variant="body1">
              {t(
                "PilotReady uses eye tracking technology to objectively measure scan patterns and cognitive workload during a flight simulation. These metrics reveal precisely what trainees struggle with and where they excel to improve performance and training."
              )}
            </Typography>
          </div>
        )}
        {(customerVariantion === "Automotive" || !customerVariantion) && (
          <div>
            <img
              src={driverReadyLogo}
              alt="Driver Ready Logo"
              className={styles.logo}
            />
            <Typography variant="body1">
              {t(
                "DriverReady measures attention and cognitive workload to quantify driver performance in a simulation environment. This feedback adds a layer of insight that accelerates driver learning and enhances vehicle design."
              )}
            </Typography>
          </div>
        )}
        {(customerVariantion === "Surgeon" || !customerVariantion) && (
          <div>
            <img
              src={surgeonReadyLogo}
              alt="Surgeon Ready Logo"
              className={styles.logo}
            />
            <Typography variant="body1">
              {t(
                "SurgeonReady identifies where surgeons are looking and their cognitive workload level during a simulation without interfering with the task.​ Instructors can use these objective metrics to evaluate performance and reduce the risk of errors."
              )}
            </Typography>
          </div>
        )}
        {(customerVariantion === "System" || !customerVariantion) && (
          <div>
            <img
              src={systemReadyLogo}
              alt="System Ready Logo"
              className={styles.logo}
            />
            <Typography variant="body1">
              {t(
                "SystemReady quantifies objective metrics such as attention and cognitive workload to maximize the usability and efficiency of human-operated systems'."
              )}
            </Typography>
          </div>
        )}
      </div>

      {videos.loading && <Spinner />}
      {(videos.data || []).map((video, index) => {
        return (
          <div key={index} className={styles.video_wrapper}>
            <Typography variant="h5">{video.name}</Typography>
            <Typography variant="body1">{video.summary}</Typography>
            <AzureMediaPlayer
              src={[{ src: video.url, type: "application/vnd.ms-sstr+xml" }]}
            />
          </div>
        );
      })}
    </div>
  );
};
