import React, { useCallback, useContext, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { useTranslation } from 'react-i18next';
import Typography from "@material-ui/core/Typography";
import PageSidebar from "../shared/layout/PageSidebar/PageSidebar";
import ReportCriteriaForm from "./ReportCriteriaForm";
import ReportDisplay from "./ReportDisplay";
import Spinner from "../shared/Spinner";
import styles from "./ReportsPage.module.css";
import api from "../utils/api";
import { ResponsiveContext } from "../shared/layout/ResponsiveContext";

const ReportsPage = props => {
  const history = useHistory();
  const [reportData, setReportData] = useState();
  const [loading, setLoading] = useState();
  const printRef = useRef(null);
  const { isTablet } = useContext(ResponsiveContext);
  const { t } = useTranslation();

  const onSubmit = useCallback(
    async ({reportConfig}) => {
      setLoading(true)
      const options = { body: JSON.stringify(reportConfig) };
      const report = await api.post('Statistics/Report', options, history);
      setLoading(false)

      setReportData(report);
    },
    [setReportData, history]
  );

  const onReset = useCallback(() => {
    setReportData(null);
  }, [setReportData]);

  return (
    <div
      className={
        classNames(
          styles.page_wrapper,
          'report_page_wrapper',
          {[styles.page_wrapper_mobile]: isTablet}
        )
      }
      ref={printRef}
    >
      <PageSidebar className={
        classNames(
          styles.sidebar,
          'sidebar',
          {[styles.sidebar_mobile]: isTablet}
        )
      }>
        <div>
          <Typography variant="h4" className="hide_print" >
            {t("Reports")}
          </Typography>
          <ReportCriteriaForm {...{ onReset, onSubmit, printRef, reportData }} />
        </div>
      </PageSidebar>
      <div className={!isTablet && styles.report_display}>
        { loading && <Spinner /> }
        { reportData && <ReportDisplay {...{reportData, printRef}} /> }
      </div>
    </div>
  );
};

export default ReportsPage;
