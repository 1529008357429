import React from "react";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import MuiTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import Button from "@material-ui/core/Button";
import Delete from "@material-ui/icons/Delete";
import styles from "./Billing.module.css";

const BillingTable = ({
  drawerOpen,
  setDrawerOpen,
  cards,
  handleDefault,
  handleRemove
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <Fab
        classes={{ root: styles.addButton }}
        onClick={() => setDrawerOpen(!drawerOpen)}
        size="medium"
        color="primary"
      >
        <AddIcon />
      </Fab>
      <div className={styles.table_wrapper}>
        <MuiTable classes={{ root: styles.table }}>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography className={styles.text_wrapper} variant="h6">
                  {t("Card")}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.text_wrapper} variant="h6">
                  {t("Expires")}
                </Typography>
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cards.map(creditCard => {
              return (
                <TableRow key={creditCard.id}>
                  <TableCell className={styles.tableCell}>
                    <Typography color="white" variant="body1">
                      {`${creditCard.brand.toUpperCase()} - ${
                        creditCard.last4
                      }`}
                    </Typography>
                  </TableCell>
                  <TableCell className={styles.tableCell}>
                    <Typography color="white" variant="body1">
                      {`${creditCard.expiryMonth} / ${creditCard.expiryYear}`}
                    </Typography>
                  </TableCell>
                  <TableCell className={styles.tableCell} align="right">
                    {creditCard.isDefault ? (
                      <Typography color="white" variant="body1">
                        {t("Default Payment Method")}
                      </Typography>
                    ) : (
                      <Button
                        color="white"
                        onClick={() => handleDefault(creditCard.id)}
                        variant="contained"
                      >
                        {t("Make Default")}
                      </Button>
                    )}
                  </TableCell>
                  <TableCell className={styles.tableCell} align="right">
                    {!creditCard.isDefault && (
                      <div onClick={() => handleRemove(creditCard)}>
                        <Delete style={{ fill: "white", cursor: "pointer" }} />
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </MuiTable>
      </div>
    </div>
  );
};

export default BillingTable;
