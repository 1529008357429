import React, { useContext } from "react";
import classNames from "classnames";
import Checkbox from "@material-ui/core/Checkbox";
import MuiTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { ResponsiveContext } from '../../shared/layout/ResponsiveContext';
import styles from "./SessionsSelect.module.css";
import { useTranslation } from 'react-i18next';

const SessionsTable = props => {
  const {
    sessions,
    checked,
    setCheckedResult,
    sortColumn,
    sortDirection,
    handleFilterChange
  } = props;

  const { t } = useTranslation();

  const columns = [
    {name: '', dataName: 'checkbox', hidden: true},
    {name: t('Subject'), dataName: 'name', sortName: "Subject"},
    {name: t('Date'), dataName: 'dateString', sortName: "Date"},
    {name: t('Test'), dataName: 'testName', sortName: "Test"},
    {name: t('Platform'), dataName: 'platformName', sortName: "Platform"},
    {name: t('Rank'), dataName: 'rankName', sortName: "Rank"},
    {name: t('Unique ID'), dataName: 'uniqueId', sortName: "UniqueId"},
  ]

  const handleColumnSort = (columnName) => {
    if (columnName) {
      if (sortColumn === columnName) {
        const direction = sortDirection === "DESC" ? "ASC" : "DESC";
        handleFilterChange("SortDirection", direction)
      } else {
        handleFilterChange("SortColumn", columnName)
      }
    }
  }

  const {isTablet} = useContext(ResponsiveContext);

  return (
    <div className={classNames(styles.rows, {[styles.rows_mobile]: isTablet})}>
      <MuiTable>
        <TableHead>
          <TableRow>
            {columns.map((col, i) => (
              <TableCell
                key={col.name + i}
                className={classNames(
                  styles.table_head,
                  {[styles.active_head]: sortColumn === (col.sortName || col.name)}
                )}
                onClick={() => handleColumnSort((col.sortName || col.name))}
              >
                <span className={styles.text_wrapper}>
                  {
                    col.name &&
                    <Typography color="inherit" variant="h6">
                      <TableSortLabel
                        className={styles.text_wrapper}
                        active={sortColumn === (col.sortName || col.name)}
                        direction={sortDirection.toLowerCase()}
                      >
                        {col.name}
                      </TableSortLabel>
                    </Typography>
                  }
                </span>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {(sessions || []).map((session, index) => {
            const isChecked = !!checked[session.resultId];

            return (
              <TableRow key={session.resultId}>
                <TableCell className={styles.checkbox_cell}>
                  <Checkbox
                    color="primary"
                    checked={isChecked}
                    onChange={e => setCheckedResult(session, e.target.checked)}
                  />
                </TableCell>
                {columns.map(column => {
                  const key = `${column.name}`;
                  const isActive = sortColumn === (column.sortName || column.name);
                  return(
                    column.hidden ? null :
                    <TableCell
                      key={key}
                      className={classNames(
                        styles.table_cell,
                        {
                          [styles.active_column]: isActive,
                          [styles.last_active_column]: (isActive && (index+1 === sessions.length)),
                        }
                      )}
                    >
                      <span className={styles.text_wrapper}>
                        <Typography color="inherit" variant="body1">
                          {session[column.dataName]}
                        </Typography>
                      </span>
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
        </TableBody>
      </MuiTable>
    </div>
  );
};

export default SessionsTable;
