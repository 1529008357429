import React from "react";
import Typography from "@material-ui/core/Typography";
import webSearch from "../assets/images/web_search.svg";
import styles from "./NoResults.module.css";

const NoResults = props => (
  <div className={styles.no_results}>
    <img alt="no_results" src={webSearch} />
    <Typography variant="h4">
      No results
    </Typography>
    {
      (props.messages || []).map(message =>
        <Typography variant="body1">
          {message}
        </Typography>
      )
    }
  </div>
);

export default NoResults;
