import { useEffect, useReducer, useState, useCallback } from "react";
import api from "./api";

const dataFetchReducer = (state, { payload, type }) => {
  switch (type) {
    case "FETCH_INIT":
      return {
        ...state,
        error: false,
        loading: true
      };
    case "FETCH_SUCCESS":
      return {
        ...state,
        data: payload,
        error: false,
        loading: false
      };
    case "FETCH_FAILURE":
      return {
        ...state,
        error: payload,
        loading: false
      };
    default:
      throw new Error();
  }
};

function useApi(
  fn,
  initialUrl,
  options,
  history,
  initialData,
  initialLoading
) {
  const [url, setUrl] = useState(initialUrl);
  const [state, dispatch] = useReducer(dataFetchReducer, {
    data: initialData || null,
    error: false,
    loading: initialLoading || true
  });
  const go = useCallback(
    async didCancel => {
      try {
        dispatch({ type: "FETCH_INIT" });
        const data = await fn(url, options, history);
        if (!didCancel) {
          if (typeof data.ok !== "undefined" && !data.ok) {
            dispatch({ type: "FETCH_FAILURE", payload: data });
          } else {
            dispatch({ type: "FETCH_SUCCESS", payload: data });
          }
        }
      } catch (err) {
        if (!didCancel) {
          dispatch({ type: "FETCH_FAILURE", payload: err });
        }
      }
    },
    [dispatch, fn, history, options, url]
  );

  useEffect(() => {
    let didCancel = false;
    go(didCancel);
    return () => {
      didCancel = true;
    };
  }, [go]);
  return [state, setUrl];
}

export function useApiDelete(
  initialUrl,
  options,
  history,
  initialData,
  initialLoading
) {
  return useApi(
    api.delete,
    initialUrl,
    options,
    history,
    initialData,
    initialLoading
  );
}

export function useApiGet(
  initialUrl,
  options,
  history,
  initialData,
  initialLoading
) {
  return useApi(
    api.get,
    initialUrl,
    options,
    history,
    initialData,
    initialLoading
  );
}

export function useApiPatch(
  initialUrl,
  options,
  history,
  initialData,
  initialLoading
) {
  return useApi(
    api.patch,
    initialUrl,
    options,
    history,
    initialData,
    initialLoading
  );
}

export function useApiPost(
  initialUrl,
  options,
  history,
  initialData,
  initialLoading
) {
  return useApi(
    api.post,
    initialUrl,
    options,
    history,
    initialData,
    initialLoading
  );
}

export function useApiPut(
  initialUrl,
  options,
  history,
  initialData,
  initialLoading
) {
  return useApi(
    api.put,
    initialUrl,
    options,
    history,
    initialData,
    initialLoading
  );
}
