import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Field, Form, Formik, ErrorMessage } from "formik";
import * as yup from "yup";
import { Checkbox } from "formik-material-ui";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import TextField from "../../shared/forms/controls/TextField";
import CountrySelect from "../../shared/forms/controls/CountrySelect";
import StateSelect from "../../shared/forms/controls/StateSelect";
import InlineFields from "../../shared/forms/layout/InlineFields";
import api from "../../utils/api";
import styles from "./Billing.module.css";

const initialValues = {
  name: "",
  address_line1: "",
  address_line2: "",
  address_city: "",
  address_state: "",
  address_zip: "",
  address_country: "United States of America",
  authorize: false
};

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required()
    .label("Name"),
  address_zip: yup
    .string()
    .required()
    .label("Zip"),
  authorize: yup
    .boolean()
    .required()
    .oneOf([true], "You must check the box to authorize us to accept payments.")
});

const CardForm = ({ getCreditCards, setDrawerOpen }) => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const [errors, setErrors] = useState(null);

  const onSubmit = async values => {
    if (!stripe || !elements) return null;

    const cardElement = elements.getElement(CardElement);
    const { error, token } = await stripe.createToken(cardElement, values);

    if (!error) {
      const response = await api.post(
        `Billing/CreditCard/${token.id}`,
        null,
        history
      );
      if (response === true) {
        setDrawerOpen(false);
        getCreditCards();
      } else {
        setErrors(`There was an error saving your card. ${response}`);
      }
    } else {
      setErrors(`There was an error saving your card. \n${error.message}`);
    }
  };

  return (
    <div className={styles.card}>
      <Formik {...{ initialValues, onSubmit, validationSchema }}>
        {({ submitForm }) => (
          <Form className={styles.form}>
            {errors && (
              <Typography align="left" variant="body1" className={styles.error}>
                {errors}
              </Typography>
            )}
            <Field
              component={TextField}
              label={t("Name on card")}
              name="name"
            />
            <div className={styles.cardElement}>
              <CardElement options={CARD_OPTIONS} />
              <div className={styles.cardElementUnderline} />
            </div>

            <Field
              component={TextField}
              label={t("Address 1")}
              name="address_line1"
            />
            <Field
              component={TextField}
              label={t("Address 2")}
              name="address_line2"
            />
            <InlineFields>
              <Field
                component={TextField}
                label={t("City")}
                name="address_city"
              />
              <Field
                component={StateSelect}
                label={t("State / Province")}
                name="address_state"
              />
            </InlineFields>
            <InlineFields>
              <Field
                component={TextField}
                label={t("Postal Code")}
                name="address_zip"
              />
              <Field
                component={CountrySelect}
                label={t("Country")}
                name="address_country"
              />
            </InlineFields>

            <div className={styles.checkbox_grid}>
              <div>
                <Field
                  component={Checkbox}
                  type="checkbox"
                  name="authorize"
                  color="primary"
                />
              </div>
              <div>
                <Typography align="left" variant="body1">
                  {t(
                    "I authorize EyeTracking LLC to send instructions to the financial institution that issued my card to take payments from my card account in accordance with the terms of our agreement with you."
                  )}
                </Typography>
                <ErrorMessage
                  name="authorize"
                  component="div"
                  className={styles.error}
                />
              </div>
            </div>

            <Button
              color="primary"
              disabled={!stripe}
              onClick={submitForm}
              variant="contained"
              className={styles.payButton}
            >
              {t("Add Card")}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const CARD_OPTIONS = {
  iconStyle: "solid",
  hidePostalCode: true,
  style: {
    base: {
      iconColor: "#fff",
      color: "#fff",
      fontWeight: 400,
      fontFamily:
        '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#fce883"
      },
      "::placeholder": {
        color: "#cccccc"
      }
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "#ffc7ee"
    }
  }
};

export default CardForm;
