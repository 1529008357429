import React from "react";
import FileUploader from "../../shared/forms/controls/FileUploader";

export default props => {
  const { platformId, platformName } = props;
  const filePath = `Platform3DConfig/${platformId}`;
  const fileName = `${platformName}_3D_Config.txt`;

  return (
    <FileUploader 
      {...{
        title: 'Platform 3D Config',
        fileType: "file",
        filePath,
        fileName,
        inputId: "platform3DConfig",
        downloadLabel: "Download 3D Config"
      }}
    />
  );
};
