import React from "react";
import compose from "lodash/fp/compose";
import * as yup from "yup";
import { emptyValuesToNull } from "../../shared/forms/fields/transforms";
import {
  initialValues as addressInitialValues,
  validationSchema as addressValidationSchema
} from "../../shared/forms/fields/AddressFields";
import {
  initialValues as emailInitialValues,
  transformValues as emailTransformValues,
  requiredValidationSchema as emailValidationSchema
} from "../../shared/forms/fields/EmailField";
import {
  initialValues as languageInitialValues,
} from "../../shared/forms/fields/LanguageField";
import {
  initialValues as passwordInitialValues,
  transformValues as passwordTransformValues,
  createValidationSchema as passwordCreateValidationSchema,
  updateValidationSchema as passwordValidationSchema
} from "../../shared/forms/fields/PasswordField";
import {
  initialValues as personNameInitialValues,
  requiredValidationSchema as personNameValidationSchema
} from "../../shared/forms/fields/PersonNameFields";
import {
  initialValues as phoneNumberInitialValues,
  validationSchema as phoneNumberValidationSchema
} from "../../shared/forms/fields/PhoneNumberFields";
import {
  initialValues as roleInitialValues,
  validationSchema as roleValidationSchema
} from "../../shared/forms/fields/RoleField";
import EntityMangementPage from "../../shared/EntityMangementPage";
import FormComponent from "./UserForm";

const columns = [
  { name: "First Name", alignment: "left", dataName: "firstName", sortName: "FirstName" },
  { name: "Last Name", alignment: "left", dataName: "lastName", sortName: "LastName" },
  { name: "Role", alignment: "left", dataName: "role", sortName: "Role" }
];
const dataUrl = `user`;
const entityName = "User";
const initialValues = {
  ...addressInitialValues,
  ...emailInitialValues,
  ...languageInitialValues,
  ...passwordInitialValues,
  ...personNameInitialValues,
  ...phoneNumberInitialValues,
  ...roleInitialValues
};
const serverEntityToFormValues = user => ({
  ...user,
  newEmail: user.email,
  newPassword: user.password
});
const transformValues = compose(
  emptyValuesToNull,
  emailTransformValues,
  passwordTransformValues
);
const validationSchema = yup.object().shape({
  ...addressValidationSchema,
  ...emailValidationSchema,
  ...passwordValidationSchema,
  ...personNameValidationSchema,
  ...phoneNumberValidationSchema,
  ...roleValidationSchema
});

const createValidationSchema = yup.object().shape({
  ...addressValidationSchema,
  ...emailValidationSchema,
  ...passwordCreateValidationSchema,
  ...personNameValidationSchema,
  ...phoneNumberValidationSchema,
  ...roleValidationSchema
});

const UsersPage = () => {
  return (
    <EntityMangementPage
      {...{
        columns,
        createValidationSchema,
        dataUrl,
        entityName,
        FormComponent,
        initialValues,
        serverEntityToFormValues,
        transformValues,
        validationSchema,
        sortColumn: "FirstName"
      }}
    />
  );
};

export default UsersPage;
