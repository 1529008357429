import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import styles from "./formStyles.module.css";

const FormStep2Label = props => {
  const {
    active,
    criteria: { reportName }
  } = props;
  const { t } = useTranslation();

  return (
    <div className={styles.labelWrapper}>
      {active || !reportName ?
        t("Choose a report") :
        <span>
          <span className="bold_print">
            {t("Report name")}
          </span>
          {` - ${reportName}`}
        </span>
      }
    </div>
  );
};

FormStep2Label.propTypes = {
  active: PropTypes.bool,
  criteria: PropTypes.shape({
    reportName: PropTypes.string
  })
};

export default FormStep2Label;
