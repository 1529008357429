import React, { useCallback, useRef } from "react";
import { useHistory } from "react-router";
import { Field } from "formik";
import * as yup from "yup";
import { useTranslation } from 'react-i18next';
import TextField from "../../controls/TextField";
import api from "../../../../utils/api";

export const initialValues = {
  uniqueId: ""
};

export const validationSchema = {
  uniqueId: yup
    .string()
    .label("Unique ID")
    .required()
    .typeError("Unique ID must be a string")
};

const UniqueIdField = ({ initialValue }) => {
  // prevValue and prevError are in place to limit the amount of times the async
  // validation runs. validation will not run unless the form's value is
  // different from the initialValue, or from the previous time validation was
  // run.
  const { t } = useTranslation();
  const history = useHistory();
  const prevValue = useRef(initialValue);
  const prevError = useRef(null);
  const validate = useCallback(
    async value => {
      if (!value) return null;
      if (value === initialValue) return null;
      if (value !== prevValue.current) {
        prevValue.current = value;
        const result = await api.get(
          `Subject/UniqueIdExists/${value}`,
          null,
          history
        );
        const error = result ? "This Unique ID is already being used" : null;
        prevError.current = error;
        return error;
      }
      return prevError.current;
    },
    [prevError, prevValue, history, initialValue]
  );

  return (
    <>
      <Field
        component={TextField}
        label={t("Unique ID")}
        name="uniqueId"
        validate={validate}
        variant="standard"
      />
    </>
  );
};

export default UniqueIdField;
