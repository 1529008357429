import React from "react";
import { Field } from "formik";
import * as yup from "yup";
import TextField from "../../controls/TextField";
import { useTranslation } from 'react-i18next';

const specialChars = "!@#$%^&*()_+=[{}];:<>|./?,-";

export const initialValues = {
  newPassword: ""
};

export const transformValues = formValues => {
  const { newPassword: password, ...restFormValues } = formValues;
  return { ...restFormValues, password };
};

const validation = yup
  .string()
  .label("Password")
  .typeError("Password must be a string");

const mutateValidation = validation
  .min(8)
  .max(35)
  .test(
    "password-complexity-contains-uppercase",
    "Passwords must contain at least one uppercase letter.",
    function(value) {
      return value ? /[A-Z]/.test(value) : true;
    }
  )
  .test(
    "password-complexity-contains-lowercase",
    "Passwords must contain at least one lowercase letter.",
    function(value) {
      return value ? /[a-z]/.test(value) : true;
    }
  )
  .test(
    "password-complexity-contains-number",
    "Passwords must contain at least one number.",
    function(value) {
      return value ? /[0-9]/.test(value) : true;
    }
  )
  .test(
    "password-complexity-number",
    "Passwords must contain at least one of these special characters.",
    function(value) {
      if (!value) return true;
      const valid = specialChars.split("").reduce((valid, char) => {
        if (valid) return valid;
        return value.includes(char);
      }, false);
      return valid;
    }
  );

export const validationSchema = {
  newPassword: validation
};

export const createValidationSchema = {
  newPassword: mutateValidation.required()
};

export const updateValidationSchema = {
  newPassword: mutateValidation
};

export default ({
  className,
  inputProps,
  label = "Password",
  variant = "standard",
  InputLabelProps
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Field
        {...{
          className,
          component: TextField,
          inputProps,
          label: t(label),
          name: "newPassword",
          type: "password",
          variant,
          InputLabelProps
        }}
      />
    </>
  );

}
