import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.css";

const InlineFields = ({ children, className }) => {
  return (
    <div className={`${styles.inlineFields} ${className || ""}`}>
      {children}
    </div>
  );
};

InlineFields.propTypes = {
  className: PropTypes.string
};
export default InlineFields;
