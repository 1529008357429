import React, { useState, useRef, useCallback } from "react";
import { useMountEffect } from "../../utils/helpers";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import SessionsDisplay from "./SessionsDisplay";
import { displayName } from "../utils";
import api from "../../utils/api";
import { useTranslation } from "react-i18next";
import "moment/min/locales";
import i18n from "../../i18n";

let initialFilters = {
  Subjects: [],
  Platforms: [],
  Ranks: [],
  Tests: [],
  StartDate: null,
  EndDate: null,
  PageNumber: 0,
  RowsPerPage: 25,
  SortColumn: "Date",
  SortDirection: "DESC"
};
const resetPageFilters = [
  "Subjects",
  "Platforms",
  "Ranks",
  "Tests",
  "StartDate",
  "EndDate",
  "RowsPerPage"
];

const SessionsSelect = props => {
  const { onSelectSessions } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const timeout = useRef(null);
  const { subjectId = "", name = "" } = useParams();
  const [sessionData, setSessionData] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState({});
  const selectedSubject = [
    { label: name, value: { name: name, id: parseInt(subjectId) } }
  ];
  if (subjectId)
    initialFilters = Object.assign({}, initialFilters, {
      Subjects: selectedSubject
    });

  const [filters, setFilters] = useState(() => {
    if (subjectId && name) return initialFilters;
    const value = localStorage.getItem("sessionFilters");
    if (value) return JSON.parse(value);
    return initialFilters;
  });

  const anyChecked = Object.values(checked).some(c => !!c);
  const sessions = sessionData ? sessionData.sessions : [];
  const totalResults = sessionData ? sessionData.recordCount : 0;

  const processResponse = response => {
    const { results: sessions, recordCount } = response;

    const locale = i18n.language || "enUS";
    moment.locale(locale);

    if (sessions) {
      sessions.forEach(session => {
        let date = new Date(session.timeCollected);
        session.date = +date;
        session.dateString = moment(date).format("MMM Do YYYY, h:mm a");
        session.name = displayName(session);
      });

      setSessionData({ sessions, recordCount });
    }
  };

  const formatFilters = filterOptions => {
    let options = Object.assign({}, filterOptions);
    options["Subjects"] = options["Subjects"]
      ? options["Subjects"].map(option => option.value.id)
      : [];
    options["Platforms"] = options["Platforms"]
      ? options["Platforms"].map(option => option.value.id)
      : [];
    options["Ranks"] = options["Ranks"]
      ? options["Ranks"].map(option => option.value.id)
      : [];
    options["Tests"] = options["Tests"]
      ? options["Tests"].map(option => option.value.id)
      : [];
    options["StartDate"] = options["StartDate"]
      ? moment(options["StartDate"]).format("YYYY-MM-DD")
      : "1970-01-01";
    options["EndDate"] = options["EndDate"]
      ? moment(options["EndDate"]).format("YYYY-MM-DD")
      : "3000-01-01";
    return options;
  };

  const getInitialData = () => {
    const getSessions = async filterOptions => {
      let options = formatFilters(filterOptions);
      options = { body: JSON.stringify({ ...options }) };
      setLoading(true);
      const response = await api.post(
        "result/summaryfiltered",
        options,
        history
      );
      setLoading(false);
      if (response) processResponse(response);
    };
    getSessions(filters);
  };

  useMountEffect(getInitialData);

  const getSessionData = async filterOptions => {
    let options = formatFilters(filterOptions);
    options = { body: JSON.stringify({ ...options }) };
    setLoading(true);
    const response = await api.post("result/summaryfiltered", options, history);
    setLoading(false);
    if (response) processResponse(response);
  };

  const handleFilterChange = (filter, value, action) => {
    let newFilters = Object.assign({}, filters);

    if (resetPageFilters.includes(filter)) newFilters["PageNumber"] = 0;
    newFilters[filter] = value;
    setFilters(newFilters);
    localStorage.setItem("sessionFilters", JSON.stringify(newFilters));

    if (
      ["PageNumber", "RowsPerPage", "SortColumn", "SortDirection"].includes(
        filter
      )
    ) {
      getSessionData(newFilters);
    } else if (action !== "select-option") {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      timeout.current = setTimeout(() => getSessionData(newFilters), 2500);
    }
  };

  const handleOpenFilter = () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = null;
    }
  };

  const handleCloseFilter = () => {
    if (!timeout.current) {
      timeout.current = setTimeout(() => getSessionData(filters), 2500);
    }
  };

  const handleClearFilters = () => {
    const resetFilters = {
      Subjects: [],
      Platforms: [],
      Ranks: [],
      Tests: [],
      StartDate: null,
      EndDate: null,
      PageNumber: 0
    };
    const newFilters = Object.assign({}, filters, resetFilters);
    setFilters(newFilters);
    localStorage.removeItem("sessionFilters");
    getSessionData(newFilters);
  };

  const handlePageChange = page => handleFilterChange("PageNumber", page);

  const handlePerPageChange = perPage =>
    handleFilterChange("RowsPerPage", perPage);

  const setCheckedResult = useCallback(
    (session, isChecked) => {
      let newChecked = Object.assign({}, checked);
      if (isChecked) {
        newChecked[session.resultId] = session;
      } else {
        delete newChecked[session.resultId];
      }
      setChecked(newChecked);
    },
    [checked]
  );

  const toggleAllChecked = useCallback(() => {
    let newChecked = {};
    if (Object.keys(checked).length === 0) {
      sessions.forEach(session => {
        newChecked[session.resultId] = session;
      });
    }
    setChecked(newChecked);
  }, [checked, sessions]);

  const onClickCompare = useCallback(() => {
    const selected = Object.keys(checked).map((key, index) => checked[key]);
    if (selected.length > 5) {
      const res = window.confirm(
        `${t("Are you sure you want to compare")} ${selected.length} ${t(
          "sessions?"
        )}`
      );
      if (!res) {
        return;
      }
    }
    onSelectSessions(selected);
  }, [checked, t, onSelectSessions]);

  return (
    <SessionsDisplay
      {...{
        sessionData,
        sessions,
        totalResults,
        loading,
        filters,
        checked,
        anyChecked,
        handleFilterChange,
        handleClearFilters,
        handleOpenFilter,
        handleCloseFilter,
        handlePageChange,
        handlePerPageChange,
        onClickCompare,
        onSelectSessions,
        setCheckedResult,
        toggleAllChecked
      }}
    />
  );
};

export default SessionsSelect;
