import React, { Component } from "react";
import classNames from "classnames";
import Typography from "@material-ui/core/Typography";
import { Route, Switch, withRouter } from "react-router-dom";
import PageSidebar from "../shared/layout/PageSidebar/PageSidebar";
import PageSidebarContent from "../shared/layout/PageSidebar/PageSidebarContent";
import PageSidebarTitle from "../shared/layout/PageSidebar/PageSidebarTitle";
import PlatformsPage from "./Platforms/PlatformsPage";
import RanksPage from "./Ranks/RanksPage";
import TestsPage from "./Tests/TestsPage";
import UsersPage from "./User/UsersPage";
import BillingPage from "./Billing/BillingPage";
import styles from "./Configs.module.css";
import { ResponsiveContext } from "../shared/layout/ResponsiveContext";
import { withTranslation } from "react-i18next";

const sideBarContent = [
  {
    ref: "users",
    text: "Users",
    route: "users",
    Component: UsersPage
  },
  {
    ref: "ranks",
    text: "Ranks",
    route: "ranks",
    Component: RanksPage
  },
  {
    ref: "platforms",
    text: "Platforms",
    route: "platforms",
    Component: PlatformsPage
  },
  {
    ref: "tests",
    text: "Tests",
    route: "tests",
    Component: TestsPage
  }
];

const billingTab = {
  ref: "billing",
  text: "Billing",
  route: "billing",
  Component: BillingPage
};

const SidebarButtons = withRouter(
  withTranslation()(props => {
    const {
      baseRoute,
      history,
      isTablet,
      match: {
        params: { page: pageParam }
      },
      t,
      sideBarItems
    } = props;

    const onSidebarClick = route => {
      history.push(`${baseRoute}${route}`);
    };

    return sideBarItems.map(button => {
      const active = pageParam === t(button.ref);
      return (
        <div
          key={button.text}
          className={classNames(styles.sidebar_item_wrapper, {
            [styles.sidebar_item_wrapper_mobile]: isTablet
          })}
          onClick={() => onSidebarClick(`/${t(button.route)}`)}
        >
          <div
            className={classNames(styles.sidebar_item, {
              [styles.sidebar_item_active]: active,
              [styles.sidebar_item_mobile]: isTablet
            })}
          >
            <Typography
              align={isTablet ? "center" : "left"}
              color="inherit"
              variant="h5"
            >
              {t(button.text)}
            </Typography>
          </div>
        </div>
      );
    });
  })
);

class Configs extends Component {
  static contextType = ResponsiveContext;

  componentDidMount() {
    const { match, history, route, t } = this.props;
    if (match.params.page === undefined) history.push(route + "/" + t("users"));
  }

  render() {
    const { route, t, user } = this.props;
    const { isMobile, isTablet } = this.context;

    const sideBarItems = user.billingTabEnabled
      ? [...sideBarContent, billingTab]
      : sideBarContent;

    return (
      <div
        className={classNames(styles.page_wrapper, {
          [styles.page_wrapper_mobile]: isTablet
        })}
      >
        <PageSidebar
          className={classNames(styles.sidebar, "sidebar", {
            [styles.sidebar_mobile]: isTablet
          })}
        >
          <div>
            <PageSidebarTitle
              className={styles.sidebar_title}
              title={t("Configure")}
            />
            {!isTablet && <div className={styles.spacer} />}
            <PageSidebarContent
              className={classNames({
                [styles.sidebar_content_tablet]: isTablet,
                [styles.sidebar_content_mobile]: isMobile
              })}
            >
              <SidebarButtons
                {...{
                  isMobile,
                  isTablet,
                  baseRoute: route,
                  sideBarItems
                }}
              />
            </PageSidebarContent>
          </div>
        </PageSidebar>
        <div className={styles.content_outer}>
          <Switch>
            {sideBarItems.map(({ ref, route: sbRoute, Component }) => {
              return (
                <Route key={ref} path={route + "/" + t(sbRoute)}>
                  <div
                    className={classNames(styles.content_wrapper, {
                      [styles.content_wrapper_tablet]: isTablet,
                      [styles.content_wrapper_mobile]: isMobile
                    })}
                  >
                    <Component />
                  </div>
                </Route>
              );
            })}
          </Switch>
        </div>
      </div>
    );
  }
}

export default withRouter(withTranslation()(Configs));
