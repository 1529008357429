import React from "react";
import PropTypes from "prop-types";
import { Field, Form, Formik } from "formik";
import ReportTypeSelect from "../shared/forms/controls/ReportTypeSelect";
import styles from "./formStyles.module.css";
import { useTranslation } from 'react-i18next';

const FormStep1Content = props => {
  const {criteriaActions: { update }, stepActions: { nextStep }} = props;
  const { t } = useTranslation();

  return (
    <div className={styles.contentWrapper}>
      <Formik>
        <Form>
          <Field
            component={ReportTypeSelect}
            name="reportType"
            variant="standard"
            placeholder={t("Select the report type")}
            onChange={({value}) => {
              update({ reportType: value.name });
              nextStep();
            }}
          />
        </Form>
      </Formik>
    </div>
  );
};

FormStep1Content.propTypes = {
  criteriaActions: PropTypes.shape({ update: PropTypes.func.isRequired }).isRequired,
  stepActions: PropTypes.shape({ nextStep: PropTypes.func.isRequired }).isRequired
};

export default FormStep1Content;
