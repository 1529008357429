import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import pluralize from "pluralize";
import { withTranslation } from 'react-i18next';
import api from "../utils/api";
import RightSideDrawer from "./layout/RightSideDrawer/RightSideDrawer";
import RightSideDrawerHeader from "./layout/RightSideDrawer/RightSideDrawerHeader";
import EntityManagementForm from "./EntityManagementForm";
import EntityManagementTable from "./EntityManagementTable";

class EntityManagementPage extends Component {
  state = {
    data: [],
    deleting: false,
    error: null,
    fetched: false,
    form: {
      editing: false,
      initialValues: null,
      open: false
    },
    loading: true,
    filters: {
      "Filter" : "",
      "PageNumber" : 0,
      "RowsPerPage" : 25,
      "SortDirection" : "ASC",
      "SortColumn" : this.props.sortColumn || "",
    },
    recordCount: 0
  };

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    const {
      dataUrl,
      entityName,
      history,
      t
    } = this.props;
    this.setState({ loading: true }, async () => {
      const options = { body: JSON.stringify({...this.state.filters}) };
      const data = await api.post(pluralize(dataUrl), options, history);
      if (typeof data.ok !== "undefined" && !data.ok) {
        this.setState({
          error: `${t("An error occurred fetching the")} ${t(pluralize(entityName).toLowerCase())}.`,
          loading: false
        });
      } else {
        const { recordCount, results } = data;
        this.setState({ data: results, error: null, fetched: true, loading: false, recordCount });
      }
    });
  };

  onAddClick = () => {
    const { initialValues } = this.props;
    this.openFormDrawer(initialValues);
  };

  onDeleteClick = async id => {
    const { t } = this.props;
    const entityName = (this.props.entityName || "item").toLowerCase();
    if (window.confirm(`${t("Are you sure you want to delete this")} ${t(entityName)}${t("?")}`)) {
      try {
        const {
          props: {
            dataUrl,
            history,
          }
        } = this;
        this.setState({ deleting: true }, async () => {
          const result = await api.delete(
            `${dataUrl}/${id}`,
            undefined,
            history
          );
          if (result.ok) {
            this.getData()
            this.setState({deleting: false});
          } else {
            this.setState({
              deleting: false,
              error: `${t("An error occurred deleting the")} ${t(entityName).toLowerCase()}`
            });
          }
        });
      } catch (err) {
        this.setState({ error: `${t("An error occurred deleting the")} ${t(entityName).toLowerCase()}` });
      }
    }
  };

  onEditClick = id => {
    const {
      props: { serverEntityToFormValues },
      state: { data }
    } = this;
    const entity = data.find(e => e.id === id);
    const formValues = serverEntityToFormValues
      ? serverEntityToFormValues(entity)
      : entity;
    this.openFormDrawer(formValues, true);
  };

  onSaveError = (err) => {
    const { entityName, t } = this.props;
    const error = err || `${t("An error occurred saving the")} ${t(entityName).toLowerCase()}.`;
    this.setState({ error });
  };

  // this function will get passed to formik's onSubmit prop
  onSubmit = async (formValues, { setSubmitting }) => {
    const {
      getData,
      closeFormDrawer,
      onSaveError,
      props: {
        dataUrl,
        history,
        transformValues,
      },
      state: {
        form: { editing }
      }
    } = this;

    const values = transformValues ? transformValues(formValues) : formValues;
    const options = { body: JSON.stringify(values) };
    const save = editing ? api.put : api.post;

    // we need to return a promise so formik can update its state regarding the
    // submit request's result
    return new Promise(async (resolve, reject) => {
      try {
        setSubmitting(false);
        const data = await save(dataUrl, options, history);
        if (data) getData()
        closeFormDrawer()
        resolve();
      } catch (err) {
        setSubmitting(false);
        onSaveError && onSaveError(err);
        reject();
      }
    });
  };

  openFormDrawer = (initialValues, editing) => {
    // this needs to be a done in a function so we can close the drawer if it's
    // open, and then reopen it with the new state to get two state updates.
    // if we don't do this, the form does not get reinititalized.
    const show = () => {
      this.setState({
        error: null,
        form: {
          editing,
          initialValues,
          open: true
        }
      });
    };

    if (this.state.form.open) {
      this.closeFormDrawer(show);
    } else {
      show();
    }
  };

  closeFormDrawer = done => {
    this.setState(
      {
        error: null,
        form: {
          editing: false,
          initialValues: null,
          open: false
        }
      },
      done
    );
  };

  handlePageChange = (page) => {
    let newFilters = Object.assign({}, this.state.filters);
    newFilters["PageNumber"] = page;
    this.setState({filters: newFilters}, () => { this.getData() })
  }

  handlePerPageChange = (perPage) => {
    let newFilters = Object.assign({}, this.state.filters);
    newFilters["PageNumber"] = 0;
    newFilters["RowsPerPage"] = perPage;
    this.setState({filters: newFilters}, () => { this.getData() })
  }

  handleSearchChange = (search) => {
    let newFilters = Object.assign({}, this.state.filters);
    newFilters["PageNumber"] = 0;
    newFilters["Filter"] = search;
    this.setState({filters: newFilters}, () => { this.getData() })
  }

  handleSortChange = (sortColumn, sortDirection) => {
    let newFilters = Object.assign({}, this.state.filters);
    newFilters["SortColumn"] = sortColumn;
    newFilters["SortDirection"] = sortDirection;
    this.setState({filters: newFilters}, () => { this.getData() })
  }

  render() {
    const {
      closeFormDrawer,
      onAddClick,
      onDeleteClick,
      onEditClick,
      onSubmit,
      handlePageChange,
      handlePerPageChange,
      handleSearchChange,
      handleSortChange,
      props: {
        className,
        columns,
        createValidationSchema,
        entityName,
        FormComponent,
        ExternalForm,
        validationSchema,
        t
      },
      state: {
        data,
        deleting,
        error,
        fetched,
        form: { initialValues, editing, open },
        loading,
        filters,
        recordCount
      }
    } = this;

    return (
      <div {...{ className }}>
        <EntityManagementTable
          {...{
            columns,
            data,
            deleting,
            entityName,
            error: open ? null : error,
            fetched,
            loading,
            onAddClick,
            onDeleteClick,
            onEditClick,
            handlePageChange,
            handlePerPageChange,
            handleSearchChange,
            handleSortChange,
            filters,
            recordCount,
            sortColumn: filters["SortColumn"],
            sortDirection: filters["SortDirection"]
          }}
        />
        <RightSideDrawer {...{ open }}>
          <RightSideDrawerHeader
            {...{
              onCloseClick: () => {
                closeFormDrawer();
              },
              title: `${editing ? t("Edit") : t("New")} ${t(entityName)}`
            }}
          />
          {open && (
            <>
              <EntityManagementForm
                {...{
                  error,
                  FormComponent,
                  initialValues,
                  onSubmit,
                  validationSchema: editing
                    ? validationSchema
                    : createValidationSchema
                    ? createValidationSchema
                    : validationSchema
                }}
              />
              {
                ExternalForm &&
                <ExternalForm {...{initialValues}} />
              }
            </>
          )}
        </RightSideDrawer>
      </div>
    );
  }
}

EntityManagementPage.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      alignment: PropTypes.string.isRequired,
      dataName: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      render: PropTypes.func
    })
  ),
  createValidationSchema: PropTypes.object,
  entityName: PropTypes.string.isRequired,
  FormComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  validationSchema: PropTypes.object.isRequired
};

export default withRouter(withTranslation()(EntityManagementPage));
