import moment from "moment";
import {useEffect} from "react";
import 'moment/min/locales';
import i18n from "../i18n";

export const getTime = () => {
  const locale = i18n.language || "enUS";
  moment.locale(locale);
  const time = moment().format("MMM Do YYYY h:mm a")
  return time;
}

export const useMountEffect = (fun) => useEffect(fun, [])
