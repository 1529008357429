import { useCallback, useReducer } from "react";

const initialState = {
  reportType: null,
  reportId: null,
  reportName: null,
  reportConfig: {},
  criteraNames: []
};

const criteriaDataReducer = (state, { payload, type }) => {
  switch (type) {
    case "RESET":
      return initialState;
    case "UPDATE":
      return {
        ...state,
        ...payload
      };
    default:
      return state;
  }
};

export default function useReportCriteriaFormReducer() {
  const [state, dispatch] = useReducer(criteriaDataReducer, initialState);
  const reset = useCallback(() => dispatch({ type: "RESET" }), [dispatch]);
  const update = useCallback(payload => dispatch({ type: "UPDATE", payload }), [
    dispatch
  ]);
  return [state, { reset, update }];
}
