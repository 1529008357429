import React from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import SubmitButtonWithProgress from "./forms/controls/SubmitButtonWithProgress";
import { Typography } from "@material-ui/core";
import styles from "./EntityManagementForm.module.css";

const nullPropsToEmptyString = entity =>
  Object.entries(entity).reduce((mapped, [key, value]) => {
    mapped[key] = value !== null ? value : "";
    return mapped;
  }, {});

const EntitiyManagementForm = props => {
  const {
    error,
    FormComponent,
    initialValues,
    onSubmit,
    validationSchema
  } = props;

  return (
    <Formik
      {...{
        initialValues: nullPropsToEmptyString(initialValues),
        onSubmit,
        validationSchema
      }}
    >
      {form => {
        const { submitForm, isSubmitting } = form;

        return (
          <>
            {error && (
              <Typography className={styles.error} variant="body1">
                {error}
              </Typography>
            )}
            <Form>
              <FormComponent {...{ ...props, form }} />
              <SubmitButtonWithProgress
                className={styles.submitButton}
                standardWidth
                {...{ isSubmitting, submitForm }}
              />
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

EntitiyManagementForm.propTypes = {
  error: PropTypes.string,
  FormComponent: PropTypes.any.isRequired,
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  validationSchema: PropTypes.object.isRequired
};

export default EntitiyManagementForm;
