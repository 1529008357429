import React from "react";
import { Field } from "formik";
import * as yup from "yup";
import TextField from "../../controls/TextField";
import { useTranslation } from 'react-i18next';

export const initialValues = {
  telCell: "",
  telOffice: ""
};

export const validationSchema = {
  telCell: yup
    .number()
    .nullable()
    .typeError("Tel (Cell) must be a number"),
  telOffice: yup
    .number()
    .nullable()
    .typeError("Tel (Office) must be a number")
};

export default () => {
  const { t } = useTranslation();

  return (
    <>
      <Field
        component={TextField}
        label={t("Tel (Office)")}
        name="telOffice"
        variant="standard"
      />
      <Field
        component={TextField}
        label={t("Tel (Cell)")}
        name="telCell"
        variant="standard"
      />
    </>
  );
}
