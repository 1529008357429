import React from "react";
import PropTypes from "prop-types";
import Creatable from "react-select/creatable";
import ReactSelect from "react-select";
import { useTranslation } from 'react-i18next';
import TextField from "@material-ui/core/TextField";

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  const {
    disableUnderline,
    error,
    ...restProps
  } = props.selectProps.textFieldProps;

  return (
    <TextField
      {...restProps}
      error={!!error}
      helperText={error}
      fullWidth
      InputLabelProps={{ shrink: true }}
      InputProps={{
        disableUnderline,
        inputComponent,
        inputProps: {
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps
        }
      }}
      variant="standard"
    />
  );
}

const Select = ({ canCreate, field, form, label, options, callback, ...props }) => {
  const { t } = useTranslation();
  const { name, onBlur, value } = field;
  const { errors, setFieldValue, touched: allTouched } = form;
  const error = t(errors[name]);
  const touched = allTouched[name];
  const selectedOption = options
    ? options.find(option => option.value === value)
    : "";
  const ReactSelectComponent = canCreate ? Creatable : ReactSelect;

  const handleChange = option => {
    const value = Array.isArray(option) ?
      option.map(o => o.value) : option && option.value;

    setFieldValue(name, value)
    callback && callback({value});
  }

  return (
    <ReactSelectComponent
      className="react-select__container"
      classNamePrefix="react-select"
      components={{ Control }}
      formatCreateLabel={value => value}
      name={name}
      onChange={handleChange}
      onBlur={onBlur}
      options={options || []}
      textFieldProps={{
        error: error && touched ? error : false,
        label,
        value:
          selectedOption && selectedOption.label ? selectedOption.label : ""
      }}
      value={selectedOption}
      placeholder={t("Select")}
      {...props}

    />
  );
};

Select.defaultProps = {
  options: []
};

Select.propTypes = {
  canCreate: PropTypes.bool,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any
    })
  )
};

export default Select;
