import React, { useCallback, useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { throttle } from "lodash";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { ResponsiveContext } from "../shared/layout/ResponsiveContext";
import styles from "./Main.module.css";
import GlobalNav from "../shared/layout/GlobalNav";
import FourOhFour from "../404";
import Configs from "../configs";
import Reports from "../reports/ReportsPage";
import Sessions from "../sessions/Sessions";
import Tutorials from "../tutorials/Tutorials";
import Subjects from "../subjects/SubjectsPage";
import api from "../utils/api";

const MainLayout = props => {
  const { children, onLogout, user } = props;
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);

  const signout = useCallback(() => {
    onLogout();
  }, [onLogout]);

  const resize = () => {
    setIsMobile(window.innerWidth <= 700);
    setIsTablet(window.innerWidth <= 1100);
  };

  useEffect(() => {
    window.addEventListener("resize", throttle(resize, 500));
    resize();
  }, []);

  return (
    <ResponsiveContext.Provider value={{ isMobile, isTablet }}>
      <div
        className={classNames(styles.outer, { [styles.is_mobile]: isTablet })}
      >
        <GlobalNav {...{ user, signout }} />
        <div
          className={classNames(styles.container, {
            [styles.mobile_container]: isTablet
          })}
        >
          {children}
        </div>
      </div>
    </ResponsiveContext.Provider>
  );
};

const billingRoles = ["Admin", "CompanyOwner", "Manager"];

const Main = props => {
  const { onLogout, user, setUser } = props;
  const { t } = useTranslation();

  useEffect(() => {
    const checkBillingTabEnabled = async loggedInUser => {
      let billingTabEnabled = false;
      if (billingRoles.includes(loggedInUser.role)) {
        billingTabEnabled = await api.get("Billing/TabEnabled", undefined, []);
      }
      setUser({ ...loggedInUser, billingTabEnabled });
    };
    checkBillingTabEnabled(user);
    // eslint-disable-next-line
  }, []);

  const customerVariantion =
    user && user.customer ? user.customer.languageVariation : null;

  return (
    <Switch>
      <Route path={`/${t("configs")}/:page?/:resource?/:id?`}>
        <MainLayout {...{ user, onLogout }}>
          <Configs route={`/${t("configs")}`} {...{ user }} />
        </MainLayout>
      </Route>
      <Route path={`/${t("reports")}`}>
        <MainLayout {...{ user, onLogout }}>
          <Reports />
        </MainLayout>
      </Route>
      <Route path={`/${t("sessions")}/:subjectId?/:name?`}>
        <MainLayout {...{ user, onLogout }}>
          <Sessions />
        </MainLayout>
      </Route>
      <Route path={`/${t("subjects")}`}>
        <MainLayout {...{ user, onLogout }}>
          <Subjects />
        </MainLayout>
      </Route>
      <Route path={`/${t("tutorials")}`}>
        <MainLayout {...{ user, onLogout }}>
          <Tutorials {...{ customerVariantion }} />
        </MainLayout>
      </Route>
      <Route exact path="/">
        <Redirect to={user ? `/${t("sessions")}` : "/login"} />
      </Route>
      <Route>
        <FourOhFour />
      </Route>
    </Switch>
  );
};

export default Main;
