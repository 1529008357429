import { __DEV__, API } from "../Constants";
import { getTokens, saveTokens, getDeviceId } from "./user";

const EnableCaching = __DEV__ && false;
const cache = {};
let refreshPromise = null;

const fetchData = async (url, options, history) => {
  const isGet =
    !options || (options && options.method && options.method === "GET");
  const isPostOrPut =
    !options ||
    (options &&
      options.method &&
      (options.method === "POST" || options.method === "PUT"));

  if (isGet && EnableCaching && cache[url]) {
    console.info(`INFO: retrieving "${url}" from cache`);
    return cache[url];
  }

  const { token } = getTokens();

  const resultData =
    isGet && EnableCaching && localStorage.getItem(`_cache_${url}`);
  if (resultData) {
    return JSON.parse(resultData);
  } else {
    options = options || {};
    options.headers = options.headers || {};
    options.headers["Content-Type"] = "application/json";
    if (token) {
      options.headers["Authorization"] = "Bearer " + token;
    }
    let result = await fetch(`${API}/${url}`, options);

    if (result.status === 401) {
      if (result.headers.get("Token-Expired") === "true") {
        // refresh token
        const { newToken, newRefreshToken } = await refreshTokens();

        if (newToken && newRefreshToken) {
          saveTokens(newToken, newRefreshToken);
          options.headers["Authorization"] = "Bearer " + newToken;
          result = await fetch(`${API}/${url}`, options);
        }

        // if still unauthorized, redirect to login
        if (result.status === 401) {
          history.push("/login");
          localStorage.removeItem("user");
        }
      } else {
        history.push("/login");
        localStorage.removeItem("user");
      }
    }

    if (result.ok) {
      if (isGet) {
        if (result.status === 204) return {};

        let data = await result.text();
        try {
          data = JSON.parse(data);
        } catch (err) {}

        return data;
      }
      if (isPostOrPut) {
        const body = await result.text();
        return body ? JSON.parse(body) : result;
      }
    }

    if (result.status === 422) {
      const body = await result.text();
      throw body;
    }

    return result;
  }
};

// File uploads/downloads require slightly different handling than
// fetchData so it was extracted into a seperate function
const fetchFile = async (url, options) => {
  const { token } = getTokens();

  options = options || {};
  options.headers = options.headers || {};
  if (token) options.headers["Authorization"] = "Bearer " + token;

  const result = await fetch(`${API}/${url}`, options);

  return result;
};

const refreshTokens = () => {
  if (refreshPromise) {
    // use existing refreshPromise if one already exists
    // this prevents multiple calls from refreshing an expired token
    return refreshPromise;
  } else {
    refreshPromise = new Promise((resolve, reject) => {
      getNewTokens()
        .then(async result => {
          const response = result.ok ? await result.json() : {};
          const newToken = response.token;
          const newRefreshToken = response.refreshToken;
          resolve({ newToken, newRefreshToken });
          refreshPromise = null;
        })
        .catch(err => {
          console.log("There was an error while refreshing the token");
          console.log(err);
          refreshPromise = null;
          reject(err);
        });
    });

    return refreshPromise;
  }
};

const getNewTokens = () => {
  const { token, refreshToken } = getTokens();
  const deviceId = getDeviceId();
  const options = {
    headers: { "Content-Type": "application/json" },
    method: "POST",
    body: JSON.stringify({ token, refreshToken, deviceId })
  };

  return fetch(`${API}/refreshToken`, options);
};

const createFetchByMethod = method => async (url, options, history) =>
  await fetchData(url, { ...(options || {}), method }, history);

const createFetchFileByMethod = method => async (url, options) =>
  await fetchFile(url, { ...(options || {}), method });

export default {
  delete: createFetchByMethod("DELETE"),
  get: createFetchByMethod("GET"),
  patch: createFetchByMethod("PATCH"),
  put: createFetchByMethod("PUT"),
  post: createFetchByMethod("POST"),
  getFile: createFetchFileByMethod("GET"),
  postFile: createFetchFileByMethod("POST")
};
