import React from "react";
import PropTypes from "prop-types";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import IconButton from "@material-ui/core/IconButton";
import MenuList from "@material-ui/core/MenuList";
import Popover from "@material-ui/core/Popover";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import MoreVert from "@material-ui/icons/MoreVert";

class DropdownMenu extends React.Component {
  static propTypes = {
    anchorOrigin: PropTypes.object,
    className: PropTypes.string,
    renderTrigger: PropTypes.func,
    transformOrigin: PropTypes.object,
    vertical: PropTypes.bool
  };

  static defaultProps = {
    renderTrigger: props => (
      <IconButton onClick={() => {}} size="small">
        {props.vertical ? <MoreVert /> : <MoreHoriz />}
      </IconButton>
    ),
    vertical: false
  };

  state = { open: false };

  handleChange = key => (_, value) => {
    this.setState({ [key]: value });
  };

  handleToggle = () => {
    this.setState({ open: !this.state.open });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const {
      children,
      className,
      renderTrigger,
      anchorOrigin,
      transformOrigin
    } = this.props;
    const { open } = this.state;

    return (
      <div className={className}>
        <div onClick={this.handleToggle} ref={node => (this.anchorEl = node)}>
          {renderTrigger(this.props)}
        </div>
        <Popover
          anchorEl={this.anchorEl}
          anchorOrigin={
            anchorOrigin || {
              vertical: "bottom",
              horizontal: "left"
            }
          }
          data-component-name={
            this.props["data-component-name"] || "DropdownMenu"
          }
          onClose={this.handleClose}
          open={open}
          transformOrigin={
            transformOrigin || {
              vertical: "top",
              horizontal: "left"
            }
          }
        >
          <ClickAwayListener onClickAway={this.handleClose}>
            <MenuList onClick={this.handleClose}>{children}</MenuList>
          </ClickAwayListener>
        </Popover>
      </div>
    );
  }
}

export default DropdownMenu;
