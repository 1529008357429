import React, { useCallback } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import styles from "./formStyles.module.css";
import { useApiGet } from "../utils/useApi";
import { useHistory } from "react-router-dom";

const FormStep2Content = props => {
  const {
    criteria: { reportType },
    criteriaActions: { update },
    stepActions: { nextStep }
  } = props;
  const history = useHistory();
  const onClick = useCallback(
    reportConfig => {
      const {id: reportId, name: reportName} = reportConfig;
      update({ reportId, reportName, reportConfig });
      nextStep();
    },
    [nextStep, update]
  );

  const [reportOptions] = useApiGet(
    `Statistics/Reports/${reportType}`,
    null,
    history
  );

  const reports = reportOptions.data || [];

  return (
    <div className={styles.contentWrapper}>
      <div>
        {reports.map(report => {
          const { id, name } = report;
          return (
            <Typography
              align="left"
              className={styles.reportListItem}
              key={id}
              onClick={() => onClick(report)}
              variant="body2"
            >
              {name}
            </Typography>
          );
        })}
      </div>
    </div>
  );
};

FormStep2Content.propTypes = {
  criteriaActions: PropTypes.shape({ update: PropTypes.func.isRequired }).isRequired,
  stepActions: PropTypes.shape({ nextStep: PropTypes.func.isRequired }).isRequired
};

export default FormStep2Content;
