import React from "react";
import PropTypes from "prop-types";
import styles from "./formStyles.module.css";
import { useTranslation } from 'react-i18next';

const FormStep1Label = props => {
  const {active, criteria: { reportType }} = props;
  const { t } = useTranslation();

  return (
    <div className={styles.labelWrapper}>
      {active || !reportType ?
        t("Choose a report type") :
        <span>
          <span className="bold_print">
            {t("Report type")}
          </span>
          {` - ${reportType}`}
        </span>
      }
    </div>
  );
};

FormStep1Label.propTypes = {
  active: PropTypes.bool,
  criteria: PropTypes.shape({ reportType: PropTypes.string })
};

export default FormStep1Label;
