import React from "react";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Close from "@material-ui/icons/Close";
import styles from "./RightSideDrawerHeader.module.css";

const RightSideDrawerHeader = ({ className, onCloseClick, title }) => {
  return (
    <div className={`${styles.rightSideDrawerHeader} ${className || ""}`}>
      <Typography variant="h5">{title || ""}</Typography>
      <IconButton onClick={onCloseClick}>
        <Close />
      </IconButton>
    </div>
  );
};

RightSideDrawerHeader.propTypes = {
  className: PropTypes.string,
  onCloseClick: PropTypes.func.isRequired,
  title: PropTypes.string
};

export default RightSideDrawerHeader;
