import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import styles from "./formStyles.module.css";

const FormStep3Label = props => {
  const {
    active,
    criteria: { criteraNames }
  } = props;
  const { t } = useTranslation();

  return (
    <div className={styles.labelWrapper}>
      <p className={`${styles.reportCritera} bold_print critera_margin`}>
        {t("Report criteria")}
      </p>
      {
        !active && criteraNames &&
          criteraNames.map((value, index) =>
            <p key={index} className={`${styles.criteraName} critera_margin`}>{value}</p>
          )
      }
    </div>
  );
};

FormStep3Label.propTypes = {
  active: PropTypes.bool,
  criteria: PropTypes.shape({
    criteraNames: PropTypes.array,
  })
};

export default FormStep3Label;
