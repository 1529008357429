import React from "react";
import Select from "../Select";
import { useApiGet } from "../../../../utils/useApi";
import { useHistory } from "react-router-dom";

const ReportTypeSelect = props => {
  const history = useHistory();
  const [reportTypes] = useApiGet("Statistics/ReportTypes", null, history);

  const options =
    reportTypes.data &&
    reportTypes.data.map(reportType => {
      const {value} = reportType;
      return({label: value.name, value});
    })

  const getOptionValue = (option) => option.value.id;

  return (
    <Select {...{ ...props, options, getOptionValue }} />
  );
};

export default ReportTypeSelect;
