import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.css";

const FieldGroup = ({ children, className, light }) => {
  return (
    <div
      className={`${styles.fieldGroup} ${className || ""} ${
        light ? styles.light : ""
      }`}
    >
      {children}
    </div>
  );
};

FieldGroup.propTypes = {
  className: PropTypes.string,
  light: PropTypes.bool
};
export default FieldGroup;
