import { API } from "../Constants";

export const roles = {
  Admin: 0,
  CompanyOwner: 1,
  Manager: 2,
  ReportViewer: 3,
  DataCollector: 4
};

export const getUser = () => {
  const userJson = localStorage.getItem("user");
  const user = userJson !== "undefined" && userJson ? JSON.parse(userJson) : {};
  return user;
};

export const getTokens = () => {
  const user = getUser();
  return { token: user.token, refreshToken: user.refreshToken };
};

export const getDeviceId = () => {
  const deviceIdJson = localStorage.getItem("deviceId");
  const deviceId =
    deviceIdJson !== "undefined" && deviceIdJson
      ? JSON.parse(deviceIdJson)
      : {};
  return deviceId;
};

export const saveTokens = (token, refreshToken) => {
  let user = getUser();
  user.token = token;
  user.refreshToken = refreshToken;
  localStorage.setItem("user", JSON.stringify(user));
};

export const saveLanguage = language => {
  let user = getUser();
  user.language = language;
  localStorage.setItem("user", JSON.stringify(user));
};

export const logoutUser = () => {
  const { token, refreshToken } = getTokens();
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    method: "POST",
    body: JSON.stringify({ refreshToken })
  };
  fetch(`${API}/logout`, options);
};
