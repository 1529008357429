import React from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import { Field, Form, Formik } from "formik";
import { useTranslation } from 'react-i18next';
import Button from "@material-ui/core/Button";
import PlatformSelect from "../shared/forms/controls/PlatformSelect";
import SubjectSelect from "../shared/forms/controls/SubjectSelect";
import TestSelect from "../shared/forms/controls/TestSelect";
import RankSelect from "../shared/forms/controls/RankSelect";
import { createYupSchema } from "./ReportCriteraValidation";
import styles from "./formStyles.module.css";

const selectComponents = {
  Platform: PlatformSelect,
  Subject: SubjectSelect,
  Test: TestSelect,
  Rank: RankSelect
};

const FormStep3Content = props => {
  const {
    criteria: { reportType, reportConfig },
    criteriaActions: { update },
    stepActions: { nextStep },
  } = props;
  const { t } = useTranslation();
  const {multiplicityCountPrimaryType: primaryCount, categories} = reportConfig;
  const isPrimaryMulti = primaryCount > 1 || primaryCount === 0;

  const onSubmit = values => {
    let primaryConfigElement = {};
    let configElements = [];
    let criteraNames = [];

    for (const [key, value] of Object.entries(values)) {
      const type = key === 'primary' ? reportType : key;
      const ids = Array.isArray(value) ? value.map(val => val.id) : [value.id];
      const name = Array.isArray(value) ? value.map(val => val.name).join(', ') : value.name;
      criteraNames.push(type + ' - ' + name)

      if (key === 'primary') {
        primaryConfigElement = { IdType: type, Ids: ids }
      } else {
        configElements.push({ IdType: type, Ids: ids })
      }
    }

    update({ primaryConfigElement, configElements, criteraNames });
    nextStep();
  };

  // set initial values for validation based on reportConfig
  let initialValues = { primary: null };
  (categories || []).forEach(category => initialValues[category.name] = null)

  // define validation rules
  const yepSchema = createYupSchema(reportConfig, t);
  const validationSchema = yup.object().shape(yepSchema);

  return (
    <div className={styles.contentWrapper}>
      <Formik {...{ initialValues, onSubmit, validationSchema }}>
        {({ submitForm }) => (
          <Form className={styles.formWrapper}>
            <Field
              component={selectComponents[reportType] || null}
              name="primary"
              variant="standard"
              isMulti={isPrimaryMulti}
              label={`${t("Select the")} ${reportType && reportType.toLowerCase()}`}
              placeholder=""
              closeMenuOnSelect={!isPrimaryMulti}
              hideSelectedOptions={true}
            />
            {(categories || []).map((category, index) => {
              const {name, heading: label, multiplicityCount: count} = category;
              const isMulti = count > 1 || count === 0

              return (
                <Field
                  key={index}
                  component={selectComponents[name] || null}
                  name={name}
                  variant="standard"
                  label={label}
                  isMulti={isMulti}
                  closeMenuOnSelect={!isMulti}
                  blurInputOnSelect={!isMulti}
                  placeholder=""
                  hideSelectedOptions={true}
                />
              );
            })}
            <Button color="primary" onClick={submitForm} variant="contained">
              {t("Continue")}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

FormStep3Content.propTypes = {
  criteria: PropTypes.shape({
    reportConfig: PropTypes.object,
    reportType: PropTypes.oneOf(
      ["None", "Subject", "Rank", "Test", "Platform", null]
    )
  }),
  criteriaActions: PropTypes.shape({ update: PropTypes.func.isRequired }).isRequired,
  stepActions: PropTypes.shape({ nextStep: PropTypes.func.isRequired }).isRequired
};

export default FormStep3Content;
