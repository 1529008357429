import React from "react";
import { useHistory } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import { useTranslation } from 'react-i18next';
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import TextField from "../../shared/forms/controls/TextField";
import api from "../../utils/api";
import styles from "./Platforms.module.css";

export default props => {
  const {updateRegions, platformId, regionNames} = props;
  const history = useHistory();
  const initialValues = { region: '' };
  const { t } = useTranslation();

  const onSubmit = async (values, {resetForm, setErrors}) => {
    const {region: name} = values;

    if (!name) return setErrors({region: 'Region name is required'})

    const options = { body: JSON.stringify({name, platformId}) };
    const region = await api.post('PlatformRegion', options, history);

    if (region && region.id) {
      updateRegions()
      resetForm(initialValues)
    } else {
      setErrors({region: 'Sorry but something went wrong'})
    }
  };

  const validate = values => {
    const errors = {};

    if (regionNames.includes(values.region)) {
      errors.region = 'Region name must be unique';
    }

    return errors;
  };

  return (
    <>
      <Formik {...{ initialValues, onSubmit, validate }}>
        {({ submitForm }) => (
          <Form>
            <div className={styles.add_region_grid}>
              <Field
                component={TextField}
                name="region"
                variant="standard"
                placeholder={t("Add New Region")}
                autoComplete="off"
              />
              <Fab onClick={submitForm} size="medium" color="primary">
                <AddIcon />
              </Fab>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
