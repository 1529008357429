import React from "react";
import * as yup from "yup";
import EntityMangementPage from "../../shared/EntityMangementPage";
import FormComponent from "./RankForm";

const columns = [{ name: "Name", alignment: "left", dataName: "name", sortName: "Name" }];
const dataUrl = "rank";
const entityName = "Rank";
const initialValues = { name: "" };
const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required()
    .label("Name")
    .typeError("Name must be a string")
});

const RanksPage = () => (
  <EntityMangementPage
    {...{
      columns,
      dataUrl,
      entityName,
      FormComponent,
      initialValues,
      validationSchema,
      sortColumn: "Name"
    }}
  />
);

export default RanksPage;
