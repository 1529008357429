import React, { useContext } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Drawer from "@material-ui/core/Drawer";
import styles from "./RightSideDrawer.module.css";
import {ResponsiveContext} from "../ResponsiveContext";

const RightSidePersistentDrawer = props => {
  const { children, open } = props;
  const { isMobile } = useContext(ResponsiveContext);
  return (
    <Drawer
      anchor="right"
      classes={{
        paper: classNames(
          styles.rightSideDrawer,
          {[styles.rightSideDrawerMobile]: isMobile}
        ),
        paperAnchorDockedRight: styles.anchor
      }}
      open={open}
      variant="persistent"
    >
      {children}
    </Drawer>
  );
};

RightSidePersistentDrawer.propTypes = {
  children: PropTypes.any,
  open: PropTypes.bool
};

export default RightSidePersistentDrawer;
