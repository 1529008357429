import React from "react";
import PropTypes from "prop-types";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Tooltip from "@material-ui/core/Tooltip";
import { useTranslation } from 'react-i18next';

function MenuAppBar(props) {
  const {
    signout,
    isTablet,
    user: { firstName, lastName }
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    signout();
  };

  const style = {
    cursor: 'pointer',
    padding: isTablet ? 0 : '30px 0'
  }

  return (
    <span>
      <div
        aria-label="user"
        aria-haspopup="true"
        onClick={handleMenu}
        style={style}
      >
        <Tooltip title={`${`${firstName} ` || ""}${lastName || ""}`}>
          <AccountCircle style={{fill: 'white'}} />
        </Tooltip>
      </div>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleLogout}>{t("Logout")}</MenuItem>
      </Menu>
    </span>
  );
}

MenuAppBar.propTypes = {
  signout: PropTypes.func.isRequired,
  user: PropTypes.object
};
export default MenuAppBar;
