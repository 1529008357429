import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import pluralize from "pluralize";
import { withTranslation } from 'react-i18next';
import Fab from "@material-ui/core/Fab";
import MuiTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import Input from "./forms/controls/Input";
import DropdownMenu from "./menus/DropdownMenu";
import Spinner from "./Spinner";
import MenuItemWithIcon from "./menus/MenuItemWithIcon";
import Pagination from "./layout/Pagination";
import NoResults from "./NoResults";
import styles from "./EntityManagementTable.module.css";
import {ResponsiveContext} from "./layout/ResponsiveContext"

class EntityManagementTable extends Component {
  static contextType = ResponsiveContext;

  constructor(props) {
    super(props);
    this.state = { filterText: "", timeout: 0 };
  }

  onFilterChange = (event) => {
    const self = this;

    if (self.state.timeout) { clearTimeout(self.state.timeout) }

    self.setState({
      filterText: event.target.value,
      timeout: setTimeout(function () {
        self.props.handleSearchChange(self.state.filterText);
      }, 1500)
    });
  }

  onSortChange = (sortName) => {
    const { sortDirection, sortColumn } = this.props;

    if (sortName) {
      let direction = "ASC";
      if (sortName === sortColumn) direction = sortDirection === "ASC" ? "DESC" : "ASC";
      this.props.handleSortChange(sortName, direction)
    }
  }

  render() {
    const { onFilterChange, onSortChange } = this;
    const { isTablet } = this.context;
    const {
      allowAdd,
      columns,
      data,
      deleting,
      error,
      entityName,
      fetched,
      loading,
      onAddClick,
      onDeleteClick,
      onEditClick,
      handlePageChange,
      handlePerPageChange,
      filters,
      recordCount,
      sortColumn,
      sortDirection,
      t
    } = this.props;

    const haveData = !loading && fetched && data && data.length > 0;

    return (
      <div className={styles.wrapper}>
        {(loading || deleting) && (
          <Spinner />
        )}
        <div className={classNames(styles.table_title_bar, {[styles.table_title_mobile]: isTablet})}>
          <Typography align="left" color="inherit" variant="h4">
            {t(pluralize(entityName))}
          </Typography>
          <Input
            className={styles.search_input}
            value={this.state.filterText}
            placeholder={t("Search")}
            onChange={onFilterChange}
          />
          {allowAdd ? (
            <Fab
              classes={{ root: styles.addButton }}
              onClick={onAddClick}
              size="medium"
              color="primary"
            >
              <AddIcon />
            </Fab>
          ) : (
            <div />
          )}
          {error && (
            <Typography className={styles.error} variant="body1">
              {error}
            </Typography>
          )}
        </div>
        {!loading && fetched && data && data.length === 0 && (
          <NoResults
            messages={[
              `${t("There are no")} ${t(pluralize(entityName).toLowerCase())}.`,
              t("Update your search or add more to get started.")
            ]}

          />
        )}
        {haveData && (
          <div className={styles.table_wrapper}>
            <MuiTable classes={{ root: styles.table }}>
              <TableHead>
                <TableRow>
                  {columns.map((col, i) => (
                    <TableCell
                      key={col.name + i}
                      align={col.alignment || "left"}
                      onClick={() => onSortChange(col.sortName)}
                      className={classNames(
                        styles.table_head,
                        {[styles.active_head]: sortColumn === col.sortName}
                      )}
                    >
                      <Typography className={styles.text_wrapper} variant="h6">
                      {
                        col.sortName ?
                          <TableSortLabel
                            className={styles.text_wrapper}
                            active={sortColumn === col.sortName}
                            direction={sortDirection.toLowerCase()}
                          >
                            {t(col.name)}
                          </TableSortLabel> :
                          t(col.name)
                      }
                      </Typography>
                    </TableCell>
                  ))}
                  <TableCell size="small"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(data || []).map((entity, index) => (
                  <TableRow key={entity.id}>
                    {columns.map(column => {
                      const key = `${column.dataName}_${entity.name}`;
                      const isActive = sortColumn && sortColumn === column.sortName;

                      return(
                        <TableCell
                          key={key}
                          className={classNames(
                            styles.table_cell,
                            {
                              [styles.active_column]: isActive,
                              [styles.last_active_column]: (isActive && (index+1 === data.length)),
                            }
                          )}
                        >
                          {
                            column.render ?
                              column.render({ meta: column.meta, data: entity, t }) :
                              <Typography color="inherit" variant="body1">
                                {entity[column.dataName]}
                              </Typography>
                          }
                        </TableCell>
                      )
                    })}
                    <TableCell className={styles.vertical_center} align="right" size="small">
                      <DropdownMenu
                        anchorOrigin={{
                          horizontal: "right",
                          vertical: "bottom"
                        }}
                      >
                        <MenuItemWithIcon
                          {...{
                            IconComponent: Edit,
                            onClick: () => onEditClick && onEditClick(entity.id),
                            text: "Edit"
                          }}
                        />
                        <MenuItemWithIcon
                          {...{
                            IconComponent: Delete,
                            onClick: () =>
                              onDeleteClick && onDeleteClick(entity.id),
                            text: "Delete"
                          }}
                        />
                      </DropdownMenu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </MuiTable>
            <Pagination
              handlePageChange={handlePageChange}
              handlePerPageChange={handlePerPageChange}
              totalResults={recordCount}
              activePage={filters["PageNumber"]}
              resultsPerPage={filters["RowsPerPage"]}
            />
          </div>
        )}
      </div>
    );
  }
}

EntityManagementTable.defaultProps = { allowAdd: true, title: "" };

EntityManagementTable.propTypes = {
  allowAdd: PropTypes.bool.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      alignment: PropTypes.string.isRequired,
      dataName: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      render: PropTypes.func
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  deleting: PropTypes.bool,
  error: PropTypes.string,
  entityName: PropTypes.string.isRequired,
  fetched: PropTypes.bool,
  loading: PropTypes.bool,
  onAddClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

export default withTranslation()(EntityManagementTable);
