import React from "react";
import { useHistory } from "react-router-dom";
import { Field } from "formik";
import * as yup from "yup";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useTranslation } from 'react-i18next';
import { useApiGet } from "../../../../utils/useApi";
import Select from "../../controls/Select";

const mapRanksToOptions = ({ data, error, loading }) => {
  return !error && !loading && data
    ? data.map(r => ({ label: r, value: r }))
    : undefined;
};

export const initialValues = {
  gender: ""
};

export const validationSchema = {
  gender: yup
    .string()
    .label("Gender")
    .typeError("Gender must be a string")
};

const GenderField = props => {
  const history = useHistory();
  const [genders] = useApiGet("genders", null, history);
  const { loading } = genders;
  const { t } = useTranslation();

  return (
    <div>
      <Field
        component={Select}
        disabled={loading}
        label={t("Gender")}
        name="gender"
        options={mapRanksToOptions(genders)}
        variant="standard"
      />
      {loading && <LinearProgress />}
    </div>
  );
};

export default GenderField;
