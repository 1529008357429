import React, { useCallback } from "react";
import * as yup from "yup";
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from "react-router";
import { Redirect, Link } from "react-router-dom";
import { Formik, Form } from "formik";
import FormHelperText from "@material-ui/core/FormHelperText";
import Typography from "@material-ui/core/Typography";
import api from "../utils/api";
import UnauthenticatedLayout from "../shared/layout/UnauthenticatedLayout";
import FieldGroup from "../shared/forms/layout/FieldGroup";
import SubmitButtonWithProgress from "../shared/forms/controls/SubmitButtonWithProgress";
import PasswordField, {
  initialValues,
  createValidationSchema as passwordValidationSchema
} from "../shared/forms/fields/PasswordField";
import styles from "./ResetPassword.module.css";

const validationSchema = yup.object().shape({
  ...passwordValidationSchema
});

export default () => {
  const history = useHistory();
  const { code } = useParams();
  const { t } = useTranslation();

  const onSubmit = useCallback(
    async (values, { setStatus }) =>
      new Promise(async resolve => {
        const genericErrorStatus = {
          success: false,
          error: "Something went wrong. Please try again"
        };

        try {
          const options = {
            body: JSON.stringify({
              code,
              password: values.newPassword
            })
          };
          const response = await api.post(
            "passwordreset",
            options,
            history
          );

          if (typeof response.ok !== "undefined") {
            if (response.ok) {
              setStatus({ success: true, error: null });
            } else {
              setStatus(genericErrorStatus);
            }
          } else {
            if (typeof response.success !== "undefined") {
              setStatus(response);
            } else {
              setStatus(genericErrorStatus);
            }
          }
          resolve();
        } catch (err) {
          setStatus(genericErrorStatus);
          resolve();
        }
      }),
    [code, history]
  );

  return (
    <UnauthenticatedLayout>
      <Formik {...{ initialValues, onSubmit, validationSchema }}>
        {form => {
          const { isSubmitting, status, submitForm } = form;
          const { error, success } = status || {};
          const isExpiredOrInvalid =
            error && error === "ResetCodeInvalidOrExpired";
          const isPasswordInvalid = error && error === "PasswordComplexity";

          const onKeyPress = useCallback(
            e => {
              if (e.key === "Enter") submitForm();
            },
            [submitForm]
          );

          return success ? (
            <Redirect to="/login/reset" />
          ) : isExpiredOrInvalid ? (
            <FieldGroup className={styles.wrapper}>
              <Typography align="left" variant="body1">
                {`${t("This reset link has expired or is invalid. You may")} `}
                <Link className={styles.link} to="/request-password-reset">
                  {t("request a new link")}
                </Link>{" "}
                {t("to continue.")}
              </Typography>
            </FieldGroup>
          ) : (
            <Form>
              <FieldGroup className={styles.wrapper}>
                <div>
                  {error && (
                    <FormHelperText className={styles.error} error>
                      {isPasswordInvalid
                        ? t("The password you entered was invalid. Please try again.")
                        : t(error)}
                    </FormHelperText>
                  )}
                  <Typography align="left" variant="body1">
                    {t("Enter your new password.")}
                  </Typography>
                </div>
                <PasswordField
                  error={!!error}
                  helperText={t(error || "")}
                  inputProps={{
                    autoComplete: "new-password"
                  }}
                  label={t("New Password")}
                  onKeyPress={onKeyPress}
                  variant="filled"
                  InputLabelProps={{style: { color: 'white'}}}
                />
                <SubmitButtonWithProgress
                  color="primary"
                  fullWidth
                  isSubmitting={isSubmitting}
                  submitForm={submitForm}
                  text="Reset Password"
                  variant="contained"
                />
              </FieldGroup>
            </Form>
          );
        }}
      </Formik>
    </UnauthenticatedLayout>
  );
};
