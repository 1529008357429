import React, {useContext} from "react";
import compose from "lodash/flowRight";
import addDays from "date-fns/addDays";
import * as yup from "yup";
import { Link, withRouter } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { emptyValuesToNull } from "../shared/forms/fields/transforms";
import {
  initialValues as addressInitialValues,
  validationSchema as addressValidationSchema
} from "../shared/forms/fields/AddressFields";
import {
  initialValues as emailInitialValues,
  transformValues as emailTransformValues,
  validationSchema as emailValidationSchema
} from "../shared/forms/fields/EmailField";
import {
  initialValues as genderInitialValues,
  validationSchema as genderValidationSchema
} from "../shared/forms/fields/GenderField";
import {
  initialValues as personNameInitialValues,
  validationSchema as personNameValidationSchema
} from "../shared/forms/fields/PersonNameFields";
import {
  initialValues as phoneNumberInitialValues,
  validationSchema as phoneNumberValidationSchema
} from "../shared/forms/fields/PhoneNumberFields";
import {
  initialValues as rankInitialValues,
  transformValues as rankTransformValues,
  validationSchema as rankValidationSchema
} from "../shared/forms/fields/RankField";
import {
  initialValues as uniqueIdInitialValues,
  validationSchema as uniqueIdValidationSchema
} from "../shared/forms/fields/UniqueIdField";
import EntityMangementPage from "../shared/EntityMangementPage";
import FormComponent from "./SubjectForm";
import styles from "./SubjectsPage.module.css";
import { useApiGet } from "../utils/useApi";
import {ResponsiveContext} from "../shared/layout/ResponsiveContext"

const SubjectColumn = ({ data, t }) => {
  const { firstName, lastName } = data;

  const subjectName =
    !firstName && !lastName
      ? t("No name entered")
      : `${lastName || ""}${
          lastName && firstName ? `, ${firstName || ""}` : firstName || ""
        }`;
  return (
    <div className={styles.subjectName}>
      <Typography color="inherit" variant="body1">
        {subjectName}
      </Typography>
    </div>
  );
};

const RankColumn = ({ data, meta }) => {
  const { rank } = data;
  const { ranks } = meta;
  const rankText =
    !ranks.loading &&
    !ranks.error &&
    ranks.data &&
    ((ranks.data.find(r => (r.id === rank)) || {}).name || "");

  return (
    <Typography className={styles.rank} color="inherit" variant="body1">
      {rankText}
    </Typography>
  );
};

const UniqueIdColumn = ({ data }) => {
  const { uniqueId } = data;

  return (
    <Typography className={styles.uniqueId} color="inherit" variant="body1">
      {uniqueId}
    </Typography>
  );
};

const SessionsColumn = ({ data }) => {
  const { sessionCount, id } = data;

  let displayName = data.firstName || "";
  if (data.firstName && data.lastName) displayName += (" " + data.lastName)
  if (!data.firstName && data.lastName) displayName += data.lastName;
  if (!displayName) displayName = data.uniqueId;

  return (
    sessionCount > 0 ? (
      <Link className={styles.sessions} to={`/sessions/${id}/${displayName}`}>
        <Typography color="inherit" variant="body1">
          {sessionCount}
        </Typography>
      </Link>
    ) : (
      <Typography className={styles.sessions} color="inherit" variant="body1">
        0
      </Typography>
    )
  );
};

const dataUrl = "subject";
const entityName = "Subject";
const initialValues = {
  ...addressInitialValues,
  ...emailInitialValues,
  ...genderInitialValues,
  ...personNameInitialValues,
  ...phoneNumberInitialValues,
  ...rankInitialValues,
  ...uniqueIdInitialValues,
  dateOfBirth: ""
};
const transformValues = compose(
  emptyValuesToNull,
  emailTransformValues,
  rankTransformValues
);
const tomorrow = addDays(new Date(), 1);
tomorrow.setHours(0);
tomorrow.setMinutes(0);
tomorrow.setSeconds(0);
const serverEntityToFormValues = subject => ({
  ...subject,
  newEmail: subject.email
});

const validationSchema = yup.object().shape({
  ...addressValidationSchema,
  ...emailValidationSchema,
  ...genderValidationSchema,
  ...personNameValidationSchema,
  ...phoneNumberValidationSchema,
  ...rankValidationSchema,
  ...uniqueIdValidationSchema,
  dateOfBirth: yup
    .date()
    .max(tomorrow)
    .label("Date of Birth")
    .typeError("Date of Birth must be a date")
});


const SubjectsPage = ({ history }) => {
  const [ranks] = useApiGet("/ranks", null, history);
  const { isMobile } = useContext(ResponsiveContext);

  const columns = [
    {
      alignment: "left",
      dataName: "subjectName",
      name: "Subject",
      render: SubjectColumn,
      sortName: "LastName"
    },
    {
      alignment: "left",
      dataName: "rank",
      meta: { ranks },
      name: "Rank",
      render: RankColumn,
      sortName: "Rank"
    },
    {
      alignment: "left",
      dataName: "uniqueId",
      name: "Unique ID",
      render: UniqueIdColumn,
      sortName: "UniqueId"
    },
    {
      alignment: "left",
      dataName: "sessionCount",
      name: "Sessions",
      render: SessionsColumn,
      sortName: "SessionCount"
    }
  ];

  return (
    <EntityMangementPage
      {...{
        className: isMobile ? styles.pageWrapperMobile : styles.pageWrapper,
        columns,
        dataUrl,
        entityName,
        FormComponent,
        initialValues,
        serverEntityToFormValues,
        transformValues,
        validationSchema,
        sortColumn: "LastName",
      }}
    />
  );
};

export default withRouter(SubjectsPage);
