import React from "react";
import PropTypes from "prop-types";
import MenuItem from "@material-ui/core/MenuItem";
import { useTranslation } from 'react-i18next';
import styles from "./MenuItemWithIcon.module.css";

const MenuItemWithIcon = props => {
  const { IconComponent, text, ...menuItemProps } = props;
  const { t } = useTranslation();
  
  return (
    <MenuItem
      {...{
        ...menuItemProps,
        classes: { root: styles.menuItemWithIcon }
      }}
    >
      <IconComponent /> {t(text)}
    </MenuItem>
  );
};

MenuItemWithIcon.propTypes = {
  IconComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  text: PropTypes.string.isRequired
};

export default MenuItemWithIcon;
