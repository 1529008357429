import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import ReactToPrint from "react-to-print";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import useStepper from "../shared/layout/Stepper/useStepper";
import FormStep1Content from "./FormStep1Content";
import FormStep2Content from "./FormStep2Content";
import FormStep3Content from "./FormStep3Content";
import FormStep1Label from "./FormStep1Label";
import FormStep2Label from "./FormStep2Label";
import FormStep3Label from "./FormStep3Label";
import useReportCriteriaFormReducer from "./useReportCriteriaFormReducer";
import styles from "./ReportCriteriaForm.module.css";
import { getTime } from "../utils/helpers";
import { useTranslation } from 'react-i18next';

const pageTitle = document.title;

const steps = [
  {
    ContentComponent: FormStep1Content,
    LabelComponent: FormStep1Label
  },
  {
    ContentComponent: FormStep2Content,
    LabelComponent: FormStep2Label
  },
  {
    ContentComponent: FormStep3Content,
    LabelComponent: FormStep3Label
  }
];

const ReportCriteriaForm = props => {
  const { onReset, onSubmit, printRef, reportData } = props;
  const [submitted, setSubmitted] = useState(false);
  const [activeStep, stepActions] = useStepper(3);
  const [criteria, criteriaActions] = useReportCriteriaFormReducer();
  const submitReportCriteria = useCallback(() => {
    const { reportId, primaryConfigElement, configElements } = criteria;
    const reportConfig = {
      ReportType: reportId,
      PrimaryTypeConfigurationElement: primaryConfigElement,
      ConfigurationElement: configElements
    }
    setSubmitted(true);
    onSubmit && onSubmit({ reportConfig });
  }, [criteria, onSubmit]);
  const contentComponentProps = {
    criteria,
    criteriaActions,
    stepActions
  };
  const completed = activeStep === steps.length;
  const { t } = useTranslation();

  return (
    <div>
      <Stepper
        activeStep={activeStep}
        classes={{ root: styles.stepper }}
        orientation="vertical"
      >
        {steps.map(({ ContentComponent, LabelComponent }, index) => {
          const labelComponentProps = {
            active: index === activeStep,
            criteria
          };

          return (
            <Step key={index}>
              <StepLabel>
                <LabelComponent {...labelComponentProps} />
              </StepLabel>
              <StepContent>
                <ContentComponent {...contentComponentProps} />
              </StepContent>
            </Step>
          );
        })}
      </Stepper>
      {criteria.reportType && (
        <div className={`${styles.buttons} hide_print`}>
          <Button
            onClick={() => {
              stepActions.reset();
              criteriaActions.reset();
              setSubmitted(false);
              onReset();
            }}
            variant="contained"
          >
            {completed ? t("Start Over") : t("Reset")}
          </Button>
          {completed && !submitted && (
            <Button
              color="primary"
              onClick={submitReportCriteria}
              variant="contained"
            >
              {t("View Report")}
            </Button>
          )}
          {
            completed && submitted && reportData &&
            <ReactToPrint
              trigger={() => (
                <Button color="primary" variant="contained">
                  {t("Print")}
                </Button>
              )}
              content={() => printRef.current}
              onBeforePrint={() =>
                (document.title = `${getTime()} ${reportData.masterTitle}`)
              }
              onAfterPrint={() => (document.title = pageTitle)}
            />
          }
        </div>
      )}
    </div>
  );
};

ReportCriteriaForm.propTypes = {
  onReset: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};
export default ReportCriteriaForm;
