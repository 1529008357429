import React from "react";
import { useHistory } from "react-router-dom";
import { Field } from "formik";
import { useTranslation } from 'react-i18next';
import { useApiGet } from "../../../../utils/useApi";
import { saveLanguage } from "../../../../utils/user";
import Select from "../../controls/Select";
import { handleLanguageChange } from "../../../../i18n";

const languagesToOptions = ({ data, error, loading }, t) => {
  let options = [{ label: t("Browser default"), value: '' }];

  if (!error && !loading && data) {
    const languages = data.map(lang => ({ label: lang, value: lang }))
    options = options.concat(languages);
  }

  return options;
};

export const initialValues = {
  language: ''
};

const LanguageField = props => {
  const { isCurrentUser } = props;
  const history = useHistory();
  const [languages] = useApiGet("languages", null, history);
  const { loading } = languages;
  const { t } = useTranslation();

  return (
    <div>
      <Field
        component={Select}
        disabled={loading}
        label={t("Language")}
        name="language"
        options={languagesToOptions(languages, t)}
        variant="standard"
        callback={({value}) => {
          if(isCurrentUser) {
            handleLanguageChange(value, history);
            saveLanguage(value);
          }
        }}
      />
    </div>
  );
};

export default LanguageField;
