import React, { useContext, useState } from "react";
import classNames from "classnames";
import { useApiGet } from "../utils/useApi";
import { useHistory } from "react-router-dom";
import AzureMediaPlayer from "../shared/AzureMediaPlayer";
import Typography from "@material-ui/core/Typography";
import styles from "./SessionVideos.module.css";
import {ResponsiveContext} from "../shared/layout/ResponsiveContext";

export default props => {
  const { session } = props;
  const history = useHistory();
  const {isMobile, isTablet} = useContext(ResponsiveContext);
  const [selectedVideo, setSelectedVideo] = useState(null)
  const [videos] = useApiGet(`Result/Videos/${session.resultId}`, null, history);

  const getUrl = urls => urls.find(url => url.split('.').pop() === "ism/manifest")

  const anyUrls =
    videos.data &&
    videos.data.map(video => video.urls).some(urls => urls && urls[0])

  return (
    <>
      {
        anyUrls &&
        <div className={
          classNames(
            styles.session_videos,
            'hide_print',
            {[styles.session_videos_mobile]: isMobile}
          )
        }>
          <Typography variant="h6">
            {`${session.name || session.uniqueId} - ${session.platformName} - ${session.testName}`}
          </Typography>
          <Typography variant="subtitle1">{session.dateString}</Typography>

          {(videos.data || []).map((video, index) => {
            const videoId = selectedVideo && selectedVideo.id;
            const selectedUrls = (selectedVideo && selectedVideo.urls) || [];
            const selectedUrl = getUrl(selectedUrls)
            const videoUrl = getUrl(video.urls || [])
            const src = selectedUrl || videoUrl;
            const autoplay = !!selectedVideo;
            const key = `${video.name}_${session.resultId}_${autoplay}`

            return (
              <div key={key}>
                {
                  src &&
                  <>
                    <p className={styles.video_link} onClick={() => setSelectedVideo(video)}>
                      {`${video.type} - ${video.name}`}
                    </p>
                    {
                      ((videoId === video.id) || (!videoId && index === 0)) &&
                      <div className={
                        classNames(
                          styles.video_player,
                          {[styles.video_player_mobile]: isTablet}
                        )
                      }>
                        <AzureMediaPlayer
                          options={{autoplay}}
                          src={[{ src, type: "application/vnd.ms-sstr+xml" }]}
                        />
                      </div>
                    }
                  </>
                }
              </div>
            )
          })}
        </div>
      }
    </>
  )
}
