import React, { Component } from 'react';
import loadLibrary from "./AzureMediaLibrary";
import styles from './AzureMediaPlayer.module.css';

class AzureMediaPlayer extends Component {
  constructor(props) {
    super(props)
    this.videoNode = React.createRef()
  }

  destroyPlayer() {
    this.player && this.player.dispose()
  }

  componentWillUnmount() {
    this.destroyPlayer()
  }

  componentDidMount() {
    const { skin } = this.props
    this.initialization = loadLibrary(skin).then(() => {
      this.createPlayer()
      this.setVideo()
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.src !== this.props.src) {
      this.initialization.then(() => this.setVideo())
    }
  }

  setVideo() {
    const { src } = this.props
    this.player.src(src)
  }

  createPlayer() {
    const { options, onInstanceCreated } = this.props
    const defaultOptions = {
      controls: true,
      logo: { enabled: false },
      hotKeys: { enableVolumeScroll: false }
    }

    this.player = window.amp(
      this.videoNode.current,
      Object.assign({}, defaultOptions, options)
    )
    onInstanceCreated && onInstanceCreated(this.player)
  }

  getRatioStyles(ratio) {
    if (!ratio) return {};
    return { paddingBottom: (ratio[1] / ratio[0]) * 100 + '%' }
  }

  render() {
    const adaptRatio = [16, 9]

    return (
      <div
        style={this.getRatioStyles(adaptRatio)}
        className={styles.azure_mp_container}
      >
        <video
          ref={this.videoNode}
          className="azuremediaplayer amp-big-play-centered amp-default-skin"
        />
      </div>
    )
  }
}

export default AzureMediaPlayer