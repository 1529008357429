import React from "react";
import * as yup from "yup";
import EntityMangementPage from "../../shared/EntityMangementPage";
import FormComponent from "./PlatformForm";
import ExternalForm from "./PlatformFormExtras";

const columns = [
  { name: "Name", alignment: "left", dataName: "name", sortName: "Name" },
  { name: "Description", alignment: "left", dataName: "description" }
];
const dataUrl = "platform";
const entityName = "Platform";
const initialValues = { name: "", description: "" };
const validationSchema = yup.object().shape({
  name: yup
    .string()
    .label("Name")
    .required()
    .typeError("Name must be a string")
});

const PlatformsPage = () => (
  <EntityMangementPage
    {...{
      columns,
      dataUrl,
      entityName,
      FormComponent,
      ExternalForm,
      initialValues,
      validationSchema,
      sortColumn: "Name"
    }}
  />
);

export default PlatformsPage;
