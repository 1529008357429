let libraryPromise = null

export default () => {
  if (libraryPromise) return libraryPromise;

  libraryPromise = new Promise((resolve, reject) => {  
    if (document.querySelector('#amp-azure')) return resolve();  
    let scriptTag = document.createElement('script')
    let linkTag = document.createElement('link')
    linkTag.rel = 'stylesheet'
    scriptTag.id = 'amp-azure'
    scriptTag.src = '//amp.azure.net/libs/amp/2.3.3/azuremediaplayer.min.js'
    linkTag.href = `//amp.azure.net/libs/amp/2.3.3/skins/amp-default/azuremediaplayer.min.css`
    document.body.appendChild(scriptTag)
    document.head.insertBefore(linkTag, document.head.firstChild)
    scriptTag.onload = () => resolve()
  })

  return libraryPromise;
}