import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import styles from "./PageSidebarTitle.module.css";

const PageSidebarTitle = props => {
  const { title } = props;
  return (
    <div className={`${styles.pageSidebarTitle} ${props.className || ""}`}>
      <Typography color="inherit" variant="h4">
        {title}
      </Typography>
    </div>
  );
};

PageSidebarTitle.propTypes = {
  title: PropTypes.string.isRequired
};

export default PageSidebarTitle;
