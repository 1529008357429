import React, { useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import styles from "./Sessions.module.css";
import SessionsSelect from "./SessionsSelect";
import SessionDetails from "./SessionDetails";

const Sessions = p => {
  let [selectedSessions, setSelectedSessions] = useState(undefined);
  const history = useHistory();
  const { t } = useTranslation();

  const goBack = useCallback(() => {
    setSelectedSessions(undefined)
    history.push(`/${t("sessions")}`)
  }, [history, t]);

  const onSelectSessions = useCallback(
    sessions => setSelectedSessions(sessions),
    [setSelectedSessions]
  );

  return (
    <React.Fragment>
      <div className={styles.main}>
        {selectedSessions && (
          <SessionDetails
            goBack={goBack}
            sessions={selectedSessions}
          />
        )}
        {!selectedSessions && (
          <SessionsSelect onSelectSessions={onSelectSessions} />
        )}
      </div>
    </React.Fragment>
  );
};

export default Sessions;
