import i18n from "i18next";
import api from "./utils/api";

let browserLanguage = navigator.language || navigator.userLanguage || "default";

i18n.init({
  lng: browserLanguage,
  resources: {
    default: {
      translations: {
        "Address 1": "Address 1",
        "Address 2": "Address 2",
        "Address 3": "Address 3",
        "Add New Region": "Add New Region",
        "Adjust your search and try again.":
          "Adjust your search and try again.",
        All: "All",
        "An error occurred fetching the": "An error occurred fetching the",
        "An error occurred deleting the": "An error occurred deleting the",
        "An error occurred saving the": "An error occurred saving the",
        "an email containing a link to reset your password will be sent to you.":
          "an email containing a link to reset your password will be sent to you.",
        "Are you sure you want to compare": "Are you sure you want to compare",
        "Are you sure you want to delete this":
          "Are you sure you want to delete this",
        "Are you sure you want to remove this":
          "Are you sure you want to remove this",
        "Browser default": "Browser default",
        Cancel: "Cancel",
        City: "City",
        Clear: "Clear",
        "Clear filters": "Clear filters",
        Choose: "Choose",
        "Choose a report type": "Choose a report type",
        "Choose a report": "Choose a report",
        "Choose new": "Choose new",
        Compare: "Compare",
        Continue: "Continue",
        Configure: "Configure",
        configs: "configs",
        Country: "Country",
        Date: "Date",
        "Date of Birth": "Date of Birth",
        "Date of Birth must be a date": "Date of Birth must be a date",
        Delete: "Delete",
        Description: "Description",
        "Deselect All": "Deselect All",
        "Didn't get an email? Request a new link below.":
          "Didn't get an email? Request a new link below.",
        Displaying: "Displaying",
        "Download 3D Config": "Download 3D Config",
        "DriverReady measures attention and cognitive workload to quantify driver performance in a simulation environment. This feedback adds a layer of insight that accelerates driver learning and enhances vehicle design.":
          "DriverReady measures attention and cognitive workload to quantify driver performance in a simulation environment. This feedback adds a layer of insight that accelerates driver learning and enhances vehicle design.",
        Edit: "Edit",
        Email: "Email",
        "Email Address": "Email Address",
        "Email is a required field": "Email is a required field",
        "Email must be a valid email": "Email must be a valid email",
        "End Date": "End Date",
        "Enter email": "Enter email",
        "Enter your email address to request a password reset link.":
          "Enter your email address to request a password reset link.",
        "Enter your new password.": "Enter your new password.",
        file: "file",
        "First Name": "First Name",
        "First Name is a required field": "First Name is a required field",
        "forgot password": "forgot password",
        Gender: "Gender",
        "If an account exists associated with your email address,":
          "If an account exists associated with your email address,",
        image: "image",
        Inv: "Inv",
        Language: "Language",
        "Last Name": "Last Name",
        "Last Name is a required field": "Last Name is a required field",
        "Learn the basics and watch tutorials to quickly get started using the client UI and the secure cloud-based platform.":
          "Learn the basics and watch tutorials to quickly get started using the client UI and the secure cloud-based platform.",
        Login: "Login",
        Logout: "Logout",
        Name: "Name",
        "Name is a required field": "Name is a required field",
        New: "New",
        "New Password": "New Password",
        "No name entered": "No name entered",
        of: "of",
        OK: "OK",
        "Page Not Found.": "Page Not Found.",
        Password: "Password",
        "Password is a required field": "Password is a required field",
        "Password must be at least 8 characters":
          "Password must be at least 8 characters",
        "Passwords must contain at least one uppercase letter.":
          "Passwords must contain at least one uppercase letter.",
        "Passwords must contain at least one lowercase letter.":
          "Passwords must contain at least one lowercase letter.",
        "Passwords must contain at least one number.":
          "Passwords must contain at least one number.",
        "Passwords must contain at least one of these special characters.":
          "Passwords must contain at least one of these special characters. !@#$%^&*()_+=[{}];:<>|./?,-",
        Performance: "Performance",
        Platform: "Platform",
        Platforms: "Platforms",
        platforms: "platforms",
        "Platform Image": "Platform Image",
        "Platform 3D Config": "Platform 3D Config",
        "Please choose a value": "Please choose a value",
        "Please double check your email and password.":
          "Please double check your email and password.",
        "PilotReady uses eye tracking technology to objectively measure scan patterns and cognitive workload during a flight simulation. These metrics reveal precisely what trainees struggle with and where they excel to improve performance and training.":
          "PilotReady uses eye tracking technology to objectively measure scan patterns and cognitive workload during a flight simulation. These metrics reveal precisely what trainees struggle with and where they excel to improve performance and training.",
        "Postal Code": "Postal Code",
        "Postal Code must be a number": "Postal Code must be a number",
        Print: "Print",
        Rank: "Rank",
        "Rank is a required field": "Rank is a required field",
        Ranks: "Ranks",
        ranks: "ranks",
        Raw: "Raw",
        region: "region",
        Regions: "Regions",
        "Region name": "Region name",
        "Region name is required": "Region name is required",
        "Region name must be unique": "Region name must be unique",
        Remove: "Remove",
        Reports: "Reports",
        reports: "reports",
        "Report name": "Report name",
        "Report criteria": "Report criteria",
        "Report type": "Report type",
        "Request Link": "Request Link",
        "Request New Link": "Request New Link",
        "request a new link": "request a new link",
        Reset: "Reset",
        "Reset Password": "Reset Password",
        Results: "Results",
        Role: "Role",
        "Role is a required field": "Role is a required field",
        "Rows per page": "Rows per page",
        "Scan Efficiency": "Scan Efficiency",
        Search: "Search",
        Select: "Select",
        "Select All": "Select All",
        "Select at least": "Select at least",
        "Select at most": "Select at most",
        "Select the": "Select the",
        "Select the report type": "Select the report type",
        Sessions: "Sessions",
        sessions: "sessions",
        "sessions?": "sessions?",
        Significant: "Significant",
        "Start Over": "Start Over",
        "State / Province": "State / Province",
        "Something went wrong. Please try again":
          "Something went wrong. Please try again",
        "Sorry but something went wrong": "Sorry but something went wrong",
        "Sorry, that filter combination has no results.":
          "Sorry, that filter combination has no results.",
        "Start Date": "Start Date",
        Subject: "Subject",
        Subjects: "Subjects",
        subjects: "subjects",
        Submit: "Submit",
        Sum: "Sum",
        "SurgeonReady identifies where surgeons are looking and their cognitive workload level during a simulation without interfering with the task.​ Instructors can use these objective metrics to evaluate performance and reduce the risk of errors.":
          "SurgeonReady identifies where surgeons are looking and their cognitive workload level during a simulation without interfering with the task.​ Instructors can use these objective metrics to evaluate performance and reduce the risk of errors.",
        "SystemReady quantifies objective metrics such as attention and cognitive workload to maximize the usability and efficiency of human-operated systems'.":
          "SystemReady quantifies objective metrics such as attention and cognitive workload to maximize the usability and efficiency of human-operated systems'.",
        "Tel (Cell)": "Tel (Cell)",
        "Tel (Cell) must be a number": "Tel (Cell) must be a number",
        "Tel (Office)": "Tel (Office)",
        "Tel (Office) must be a number": "Tel (Office) must be a number",
        Test: "Test",
        Tests: "Tests",
        tests: "tests",
        "The file must be less than 3 MB": "The file must be less than 3 MB",
        "The file must be a jpg, png, bmp, gif, or tiff":
          "The file must be a jpg, png, bmp, gif, or tiff",
        "The login attempt failed.": "The login attempt failed.",
        "The password you entered was invalid. Please try again.":
          "The password you entered was invalid. Please try again.",
        "There are no": "There are no",
        "There was an error uploading the": "There was an error uploading the",
        "There was an error deleting the": "There was an error deleting the",
        "This reset link has expired or is invalid. You may":
          "This reset link has expired or is invalid. You may",
        "This Unique ID is already being used":
          "This Unique ID is already being used",
        Time: "Time",
        "to continue.": "to continue.",
        Tutorials: "Tutorials",
        tutorials: "tutorials",
        "Unique ID": "Unique ID",
        "Unique ID is a required field": "Unique ID is a required field",
        "Update your search or add more to get started.":
          "Update your search or add more to get started.",
        User: "User",
        Users: "Users",
        users: "users",
        "View Report": "View Report",
        "We're sorry. We could not find the page you were looking for.":
          "We're sorry. We could not find the page you were looking for.",
        Workload: "Workload",
        "Your password was reset successfully.":
          "Your password was reset successfully.",
        "Your request has been sent.": "Your request has been sent.",
        "?": "?"
      }
    }
  },
  fallbackLng: "default",
  defaultNS: "translations",
  keySeparator: false
});

export const handleLanguageChange = async (
  newLanguage,
  history = [],
  getLanguage
) => {
  if (!newLanguage) newLanguage = browserLanguage;

  const resourceExists = i18n.hasResourceBundle(newLanguage, "translations");

  if (!resourceExists || getLanguage) {
    const language = await api.get(
      `Language/Translation/${newLanguage}`,
      null,
      history
    );

    if (language && language.translation) {
      i18n.addResourceBundle(
        newLanguage,
        "translations",
        language.translation,
        true,
        true
      );
    }
  }

  i18n.changeLanguage(newLanguage);
};

export default i18n;
