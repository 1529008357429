import React, { useRef, useEffect, useState} from "react";
import { useHistory } from "react-router-dom";
import Select from "../Select";
import api from "../../../../utils/api";

const defaultFilters = {
  "Filter" : "",
  "PageNumber" : 0,
  "RowsPerPage" : 100,
  "SortDirection" : "ASC",
  "SortColumn" : "Name",
}

const AsyncSelect = ({ formatOptions, loadPath, sortColumn, ...props }) => {
  const history = useHistory();
  const timeout = useRef(null);
  const [loadedOptions, setLoadedOptions] = useState(null);
  const [maxResults, setMaxResults] = useState(null);
  const [search, setSearch] = useState(null);

  useEffect(() => {
    if (loadPath) {
      if (maxResults && maxResults < defaultFilters["RowsPerPage"]) return;

      const getOptions = async (search) => {
        let filters = Object.assign({}, defaultFilters);
        filters["Filter"] = search || "";
        filters["SortColumn"] = sortColumn || "Name";
        filters = { body: JSON.stringify({...filters}) };
        const response = await api.post(loadPath, filters, history);

        if (response && response.results) {
          const data = formatOptions(response.results)
          setLoadedOptions(data)
          if (!search) setMaxResults(response.recordCount)
        }
      }

      if (!search) {
        getOptions(search)
      } else {
        if (timeout.current) { clearTimeout(timeout.current) }
        timeout.current = setTimeout(() => getOptions(search), 300);
      }
    }
    // useEffect only needs to run when the search is updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, history])


  return (
    <Select
      {...{...props}}
      onInputChange={(value) => setSearch(value)}
      options={loadedOptions || []}
    />
  );
};

export default AsyncSelect;
