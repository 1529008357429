import React from "react";
import PropTypes from "prop-types";
import styles from "./PageSidebar.module.css";

const PageSidebarContent = props => (
  <div className={`${styles.pageSidebarContent} ${props.className || ""}`}>
    {props.children}
  </div>
);

PageSidebarContent.propTypes = { children: PropTypes.any };

export default PageSidebarContent;
