import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import * as yup from "yup";
import TextField from "../../controls/TextField";
import { useTranslation } from 'react-i18next';

export const initialValues = {
  newEmail: ""
};

const validation = yup
  .string()
  .label("Email")
  .email()
  .typeError("Email must be a string");

export const validationSchema = {
  newEmail: validation
};

export const requiredValidationSchema = {
  newEmail: validation.required()
};

export const transformValues = formValues => {
  const { newEmail: email, ...restFormValues } = formValues;
  return { ...restFormValues, email };
};

const EmailField = ({ className, label = "Email", variant = "standard", InputLabelProps }) => {
  const { t } = useTranslation();

  return (
    <>
      <Field
        {...{ className, component: TextField, label: t(label), name: "newEmail", variant, InputLabelProps }}
      />
    </>
  );
}

EmailField.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  variant: PropTypes.string,
  InputLabelProps: PropTypes.object,
};

export default EmailField;
