import React from "react";
import AsyncSelect from "../AsyncSelect";

const SubjectSelect = props => {
  const loadPath = 'subjects';
  const sortColumn = 'FirstName';

  const formatOptions = (options) => {
    let formatted = [];

    formatted = (options || []).map(subject => {
      let name = subject.firstName || "";
      if (subject.firstName && subject.lastName) name += (" " + subject.lastName)
      if (!subject.firstName && subject.lastName) name += subject.lastName;
      if (!name) name = subject.uniqueId;

      return({label: name, value: {id: subject.id, name }});
    })

    return formatted;
  }

  const getOptionValue = (option) => option.value ? option.value.id : option.id;

  return (
    <AsyncSelect
      {...{
        ...props,
        getOptionValue,
        formatOptions,
        loadPath,
        sortColumn
      }}
    />
  );
}


export default SubjectSelect;
