import React from "react";
import PlatformRegions from "./PlatformRegions";
import PlatformImage from "./PlatformImage";
import PlatformConfig from "./PlatformConfig";

export default props => {
  const platformId = props.initialValues && props.initialValues.id;
  const platformName = props.initialValues && props.initialValues.name;

  return (
    <>
      { 
        platformId &&
        <>
          <PlatformRegions {...{platformId}} />
          <PlatformImage {...{platformId}} />
          <PlatformConfig {...{platformId, platformName}} />
        </>
      }
    </>
  );
};
