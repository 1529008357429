import React from "react";
import { CircularProgress } from "@material-ui/core";
import styles from "./Spinner.module.css";

// The spinner fills the available space of the containing parent
// Note that the parent should be position relative
export default () => {
  return (
    <div className={styles.spinner}>
      <CircularProgress />
    </div>
  );
}
