import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import { DatePicker as MuiDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers/";
import { useTranslation } from 'react-i18next';
import i18n from "../../../../i18n";
import 'moment/min/locales';

const DatePicker = ({ field, form, ...restProps }) => {
  const { t } = useTranslation();
  const locale = i18n.language || "enUS";
  moment.locale(locale);

  const name = field ? field.name : restProps.name;
  const value = field ? field.value : restProps.value;
  const errors = form ? form.errors : restProps.errors || {};
  const setFieldValue = form ? form.setFieldValue : undefined;
  const error = t(errors[name]);

  const handleChange = date => {
    const value = date ? date._d : null;
    setFieldValue(name, value)
  }

  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={locale}>
      <MuiDatePicker
        {...{
          emptyLabel: "",
          error: !!error,
          helperText: error,
          name,
          onChange: handleChange,
          value: value || null,
          cancelLabel: t("Cancel"),
          clearLabel: t("Clear"),
          okLabel: t("OK"),
          ...restProps,
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

DatePicker.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object
};

export default DatePicker;
