import React, { useContext } from "react";
import classNames from "classnames";
import Typography from "@material-ui/core/Typography";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import ReportTable from "./ReportTable";
import styles from "./ReportDisplay.module.css";
import { ResponsiveContext } from "../shared/layout/ResponsiveContext";

const getChartOptions = report => {
  if (!report) return {};

  return {
    chart: {
      styledMode: true,
      zoomType: "x"
    },
    title: {
      text: ""
    },
    xAxis: {
      title: {
        text: report.xAxisLabel
      }
    },
    yAxis: {
      title: {
        text: report.yAxisLabel
      }
    },
    series: (report.dataSeries || []).map(series => ({
      type: series.style,
      name: series.name,
      data: (series.points || []).map(point => ({x: point.x, y: point.y, name: point.tag}))
    }))
  };
};

const ReportDisplay = props => {
  const { reportData } = props;
  const { isMobile, isTablet } = useContext(ResponsiveContext);

  return (
    <div className={
      classNames(
        styles.report_wrapper,
        'report_wrapper',
        {
          [styles.report_wrapper_mobile]: isMobile,
          [styles.report_wrapper_tablet]: isTablet
        }
      )
    }>
      <Typography className={`${styles.title} report_title`} variant="h4">
        {reportData.masterTitle}
      </Typography>
      <Typography className={`${styles.summary} summary`}>
        {reportData.writtenSummary}
      </Typography>
      {
        (reportData.results || []).map((result, index) => {
          const chartOptions = getChartOptions(result);

          return (
            <div className={`${styles.result_wrapper} avoid_page_break`} key={index}>
              {
                (result.title || result.writtenSummary) &&
                <div className="avoid_page_break">
                  {
                    result.title &&
                    <Typography className={`${styles.subtitle} subtitle`} variant="h5">
                      {result.title}
                    </Typography>
                  }
                  {
                    result.writtenSummary &&
                    <Typography className={`${styles.summary} summary`}>
                      {result.writtenSummary}
                    </Typography>
                  }
                </div>
              }
              {
                result.dataSeries &&
                <HighchartsReact
                  className="avoid_page_break"
                  highcharts={Highcharts}
                  options={chartOptions}
                />
              }
              {
                result.dataTable &&
                <ReportTable
                  className="avoid_page_break"
                  dataTable={result.dataTable}
                />
              }
            </div>
          )
        })
      }
    </div>
  );
};

export default ReportDisplay;
