import React, { useEffect } from "react";
import styles from "./UnauthenticatedLayout.module.css";
import whiteLogo from "../../assets/images/logo-white.svg";
import { handleLanguageChange } from "../../i18n";

const UnauthenticatedLayout = ({ children }) => {
  // TODO API calls for backend translations currently fail because they must be authenticated.
  // Unauthenticated pages (ex. login) aren't actually translated since the API call throws a 401.
  useEffect(() => {handleLanguageChange()}, [])

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.contentWrapper}>
        <div className={styles.formWrapper}>
          <img alt="logo" className={styles.logo} src={whiteLogo} />
          {children}
        </div>
      </div>
    </div>
  );
}

export default UnauthenticatedLayout;
