import React, { useCallback } from "react";
import TextField from '@material-ui/core/TextField';
import { fieldToTextField } from 'formik-material-ui';
import { useTranslation } from 'react-i18next';

// This textfield is meant to be used with formik
// and is necessary to handle the error translation

export default (props) => {
  const { field: {name}, form: {errors, setFieldValue} } = props;
  const { t } = useTranslation();

  const error = t(errors[name]);

  const handleChange = useCallback(
    event => {
      const { value } = event.target;
      setFieldValue(name, value || '');
    },
    [setFieldValue, name]
  );

  return (
    <TextField
      {...fieldToTextField(props)}
      error={!!error}
      helperText={error}
      onChange={handleChange}
    />
  );
}
