import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from 'react-i18next';
import PlatformRegionForm from "./PlatformRegionForm";
import PlatformRegion from "./PlatformRegion";
import api from "../../utils/api";
import styles from "./Platforms.module.css";

export default props => {
  const {platformId} = props;
  const history = useHistory();
  const [regions, setRegions] = useState([]);

  const getRegions =  async (platformId, history) => {
    const response = await api.get(`PlatformRegions/${platformId}`, null, history);
    if (response && response.length > 0) setRegions(response);
  }

  useEffect(() => { getRegions(platformId, history) }, [platformId, history])

  const updateRegions = () => { getRegions(platformId, history) };

  const regionNames = regions.map(region => region.name);

  const { t } = useTranslation();

  return (
    <>
      <div className={styles.regions}>
        <Typography variant="subtitle1">
          {t("Regions")}
        </Typography>
        <PlatformRegionForm
          {...{
            updateRegions,
            platformId,
            regionNames
          }}
        />
        <div>
          {
            (regions || []).map(region =>
              <PlatformRegion
                key={region.id}
                {...{
                  region,
                  updateRegions,
                  regionNames
                }}
              />
            )
          }
        </div>
      </div>
    </>
  );
};
