import React, {useContext} from "react";
import classNames from "classnames";
import styles from "./Pagination.module.css";
import Typography from "@material-ui/core/Typography";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons"
import { ResponsiveContext } from '../ResponsiveContext';
import { useTranslation } from 'react-i18next';

const Pagination = props => {
  const {
    activePage,
    resultsPerPage,
    handlePageChange,
    handlePerPageChange,
    totalResults,
  } = props;
  const { t } = useTranslation();
  const {isMobile, isTablet} = useContext(ResponsiveContext);
  const maxPages = isTablet ? 7 : 10;

  const numberOfPages = Math.floor((totalResults + resultsPerPage - 1) / resultsPerPage);
  const to = (activePage) * resultsPerPage + 1
  const from = (activePage+1 === numberOfPages) ? totalResults : (activePage + 1) * resultsPerPage;
  const halfMax = Math.floor(maxPages/2);
  const offset = isTablet ? 1 : 2;

  let pages = Array.from({length: numberOfPages}, (v, k) => k+1);
  if (numberOfPages > maxPages) {
    if (activePage < halfMax) {
      pages = Array.from({length: (maxPages-1)}, (v, k) => k+1);
      pages.push(numberOfPages)
    } else if (activePage > (numberOfPages - halfMax)) {
      pages = [1]
      pages = pages.concat(Array.from({length: (maxPages-1)}, (v, k) => k+2+(numberOfPages-maxPages)));
    }
    else {
      pages = [1]
      pages = pages.concat(Array.from({length: (maxPages-2)}, (v, k) => k+activePage-offset));
      pages.push(numberOfPages)
    }
  }

  const handleNextClick = () => {
    if ((activePage + 1) < numberOfPages) handlePageChange(activePage + 1)
  }

  const handlePreviousClick = () => {
    if (activePage > 0) handlePageChange(activePage - 1)
  }

  const toFromText = (to, from, totalResults) => {
    if (from === totalResults) return `${t("Displaying")} ${to} - ${from}`;

    return `${t("Displaying")} ${to} - ${from} ${t("of")} ${totalResults}`
  }

  return (
    <div className={classNames(
      styles.pagination_wrapper,
      {[styles.pagination_wrapper_mobile]: isMobile})
    }>
      <div className={styles.rows_per_page}>
        <Typography variant="body2">
          {t("Rows per page")}
        </Typography>
        <Select value={resultsPerPage} onChange={(e) => handlePerPageChange(e.target.value)}>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={25}>25</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
          <MenuItem value={200}>200</MenuItem>
        </Select>
      </div>
      <span />
      {
        totalResults > resultsPerPage ?
          <div className={styles.pagination_pages}>
            <span
              className={classNames({[styles.disabled]: activePage === 0})}
              onClick={handlePreviousClick}
            >
              <KeyboardArrowLeft />
            </span>
            {
              pages.map(page =>
                <span
                  key={page}
                  onClick={() => handlePageChange(page-1)}
                  className={classNames({[styles.active]: (activePage + 1) === page})}
                >
                  {page}
                </span>
              )
            }
            <span
              className={classNames({[styles.disabled]: (activePage + 1) === numberOfPages})}
              onClick={handleNextClick}
            >
              <KeyboardArrowRight />
            </span>
          </div> : <span/>
      }
      <span />
      <div>
        <Typography variant="body2">
          {toFromText(to, from, totalResults)}
        </Typography>
      </div>
    </div>
  )
}

export default Pagination;
