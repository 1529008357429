import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useTranslation } from 'react-i18next';
import styles from "./styles.module.css";

const SubmitButtonWithProgress = ({
  className,
  isSubmitting,
  standardWidth,
  submitForm,
  text
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={`${styles.submitButtonWithProgress} ${className || ""} ${
        !standardWidth ? styles.fullWidth : ""
      }`}
    >
      {isSubmitting && <LinearProgress />}
      <Button
        classes={{ root: standardWidth ? styles.standardWidth : "" }}
        color="primary"
        disabled={isSubmitting}
        onClick={submitForm}
        variant="contained"
      >
        {t(text || "Submit")}
      </Button>
    </div>
  );
};

SubmitButtonWithProgress.propTypes = {
  className: PropTypes.string,
  isSubmitting: PropTypes.bool,
  standardWidth: PropTypes.bool,
  submitForm: PropTypes.func.isRequired,
  text: PropTypes.string
};
export default SubmitButtonWithProgress;
