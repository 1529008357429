import React from "react";
import { useHistory } from "react-router-dom";
import { Field } from "formik";
import * as yup from "yup";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useTranslation } from 'react-i18next';
import { useApiGet } from "../../../../utils/useApi";
import Select from "../../controls/Select";

const mapRanksToOptions = ({ data, error, loading }) => {
  return !error && !loading && data
    ? data.map(r => ({ label: r.name, value: r.id }))
    : undefined;
};

export const initialValues = {
  rank: ""
};

export const validationSchema = {
  rank: yup
    .number()
    .label("Rank")
    .required()
    .typeError("Rank must be a number")
};

export const transformValues = formValues => {
  const { rank: formRank, ...restFormValues } = formValues;
  let rank = isNaN(parseInt(formRank)) ? null : parseInt(formRank);
  return { ...restFormValues, rank };
};

const RankField = props => {
  const history = useHistory();
  const [ranks] = useApiGet("ranks", null, history);
  const { loading } = ranks;
  const { t } = useTranslation();

  return (
    <div>
      <Field
        component={Select}
        disabled={loading}
        label={t("Rank")}
        name="rank"
        options={mapRanksToOptions(ranks)}
        variant="standard"
      />
      {loading && <LinearProgress />}
    </div>
  );
};

export default RankField;
