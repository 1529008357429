import React, { useContext } from "react";
import classNames from "classnames";
import { useTranslation } from 'react-i18next';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {Star} from '@material-ui/icons';
import styles from "./ReportTable.module.css";
import { ResponsiveContext } from "../shared/layout/ResponsiveContext";

const ReportTable = props => {
  const { dataTable: {header: columns, dataRows: rows} } = props;
  const { isMobile, isTablet } = useContext(ResponsiveContext);
  const { t } = useTranslation();

  const renderValue = value => {
    if (value === "[IMG_STATSIG]")
      return <Star className={styles.star} />

    if (value === "[IMG_INV_STATSIG]")
      return <span className={styles.star_red}>{t("Inv")} <Star /></span>

    return value;
  }

  const getAlign = columnName => {
    if (columnName === t('Significant')) return 'center'
    return 'left';
  }

  return (
    <div className={
      classNames(
        styles.table_wrapper,
        {
          [styles.table_wrapper_tablet]: isTablet,
          [styles.table_wrapper_mobile]: isMobile,
        }
      )
    }>
      <Table>
        <TableHead>
          <TableRow>
            {
              columns.map(column =>
                <TableCell
                  key={column.columnId}
                  className={styles.header}
                  align={getAlign(column.text)}
                >
                  {column.text}
                </TableCell>
              )
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {
            rows.map((row, index) => (
              <TableRow key={index}>
                {
                  columns.map(column => {
                    const cell = row.find(cell => cell.columnId === column.columnId)

                    return (
                      <TableCell
                        key={cell.columnId}
                        className={styles.cell}
                        align={getAlign(column.text)}
                      >
                        { renderValue(cell.value) }
                      </TableCell>
                    )
                  })
                }
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </div>
  );
};

export default ReportTable;
