import React from "react";
import FieldGroup from "../../shared/forms/layout/FieldGroup";
import InlineFields from "../../shared/forms/layout/InlineFields";
import AddressFields from "../../shared/forms/fields/AddressFields";
import EmailField from "../../shared/forms/fields/EmailField";
import LanguageField from "../../shared/forms/fields/LanguageField";
import PasswordField from "../../shared/forms/fields/PasswordField";
import PersonNameFields from "../../shared/forms/fields/PersonNameFields";
import PhoneNumberFields from "../../shared/forms/fields/PhoneNumberFields";
import RoleField from "../../shared/forms/fields/RoleField";
import { getUser, roles } from "../../utils/user";

const UserForm = ({ initialValues }) => {
  const currentUser = getUser();
  const isCurrentUser = initialValues.id === currentUser.id;
  const showPasswordField = (
    !initialValues.id ||
    (
      currentUser.role !== "ReportViewer" &&
      currentUser.role !== "DataCollector" &&
      roles[currentUser.role] <= roles[initialValues.role]
    )
  )

  return (
    <>
      <FieldGroup light>
        <InlineFields>
          <PersonNameFields />
        </InlineFields>
        <LanguageField {...{isCurrentUser}} />
      </FieldGroup>
      <FieldGroup>
        <AddressFields />
      </FieldGroup>
      <FieldGroup light>
        <PhoneNumberFields />
        <EmailField />
      </FieldGroup>
      <FieldGroup>
        <RoleField />
        {
          showPasswordField &&
          <PasswordField
            inputProps={{
              autoComplete: "new-password"
            }}
          />
        }
      </FieldGroup>
    </>
  );
}

export default UserForm;
