import React, { useEffect, useState } from "react";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import api from "../../utils/api";
import Spinner from "../../shared/Spinner";
import RightSideDrawer from "../../shared/layout/RightSideDrawer/RightSideDrawer";
import RightSideDrawerHeader from "../../shared/layout/RightSideDrawer/RightSideDrawerHeader";
import BillingTable from "./BillingTable";
import CardForm from "./CardForm";
import styles from "./Billing.module.css";

const BillingPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [statusLoading, setStatusLoading] = useState(false);
  const [cardsLoading, setCardsLoading] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [trialStatus, setTrialStatus] = useState(null);
  const [creditCards, setCreditCards] = useState(null);

  const getCreditCards = async () => {
    setCardsLoading(true);
    const result = await api.get("Billing/CreditCards", undefined, history);
    setCreditCards(result);
    setCardsLoading(false);
  };

  const getTrialStatus = async () => {
    setStatusLoading(true);
    const result = await api.get(
      "Billing/TrialSubscription",
      undefined,
      history
    );
    setTrialStatus(result);
    setStatusLoading(false);
  };

  useEffect(() => {
    getCreditCards();
    getTrialStatus();
    // eslint-disable-next-line
  }, []);

  const handleRemove = async card => {
    const cardName = `${card.brand.toUpperCase()} ${card.last4}`;
    const cardExpires = `${card.expiryMonth}/${card.expiryYear}`;
    const confirmText = `Are you sure you want to remove ${cardName} - ${cardExpires}?`;

    if (window.confirm(confirmText)) {
      setCardsLoading(true);
      await api.delete(`Billing/CreditCard/${card.id}`, null, history);
      getCreditCards();
    }
  };

  const handleDefault = async cardId => {
    setCardsLoading(true);
    await api.put(`Billing/CreditCard/Default/${cardId}`, null, history);
    getCreditCards();
  };

  const cards = creditCards?.sort((a, b) => b?.isDefault - a?.isDefault);
  const loading = cardsLoading || statusLoading;

  const endDateValid =
    !trialStatus?.trialEndDate ||
    trialStatus?.trialEndDate === "0001-01-01T00:00:00";
  const trialEndDate = endDateValid
    ? "not currently available"
    : moment(trialStatus.trialEndDate).format("MMM Do YYYY");

  const nextBillValid =
    !trialStatus?.nextBillValid ||
    trialStatus?.nextBillDate === "0001-01-01T00:00:00";
  const nextBillDate = nextBillValid
    ? "not currently available"
    : moment(trialStatus.nextBillDate).format("MMM Do YYYY");

  return (
    <div className={styles.pageWrapper}>
      <Typography
        align="left"
        color="white"
        variant="h4"
        className={styles.title}
      >
        {t("Billing")}
      </Typography>
      {loading && <Spinner />}
      {trialStatus && (
        <div className={styles.statusWrapper}>
          {trialStatus.isTrial ? (
            <Typography color="white" variant="body1" align="left">
              {`You're on a trial until ${trialEndDate}.`}
            </Typography>
          ) : (
            <Typography
              className={styles.prewrap}
              color="white"
              variant="body1"
              align="left"
            >
              {`You're on a paid account. \nThe next billing date is ${nextBillDate}.`}
            </Typography>
          )}
        </div>
      )}

      <RightSideDrawer open={drawerOpen}>
        <RightSideDrawerHeader
          onCloseClick={() => setDrawerOpen(false)}
          title={`${t("New Card")}`}
        />
        {drawerOpen && <CardForm {...{ getCreditCards, setDrawerOpen }} />}
      </RightSideDrawer>

      {creditCards?.length === 0 && (
        <div>
          <Typography
            className={styles.noPaymentMethods}
            color="white"
            variant="body1"
            align="left"
          >
            {t("You don't have any payment methods saved.")}
          </Typography>
          <Typography
            className={styles.clickHereText}
            color="white"
            variant="body1"
            align="left"
          >
            <span
              align="left"
              onClick={() => setDrawerOpen(!drawerOpen)}
              variant="contained"
              className={styles.clickHere}
            >
              {t("Click here")}
            </span>
            {t(" to add a payment method.")}
          </Typography>
        </div>
      )}
      {cards?.length > 0 && (
        <BillingTable
          {...{ drawerOpen, setDrawerOpen, cards, handleDefault, handleRemove }}
        />
      )}
    </div>
  );
};

export default BillingPage;
