import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import api from "../../../../utils/api";
import styles from "./styles.module.css";

const FileUploader = props => {
  const { fileType, filePath, fileName, inputType, inputId, title, downloadLabel, validate } = props;
  const [selectedFile, setSelectedFile] = useState(undefined);
  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [error, setError] = useState(null);
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => { getFile(filePath, fileType) }, [filePath, fileType])

  const getFile =  async (filePath, fileType) => {
    const response = await api.getFile(filePath, null);

    if (response.ok) {
      const blob = await response.blob();
      const fileUrl = URL.createObjectURL(blob);
      setFile(fileUrl);
      setFilePreview(null);
    } else {
      console.log(`There was an error getting the ${fileType}`);
    }
  }

  const handleUpload = async () => {
    const errors = validate ? validate(selectedFile) : null;
    if (errors) return setError(errors)

    const data = new FormData()
    data.append('file', selectedFile)
    const options = { body: data };

    const response = await api.postFile(filePath, options)

    if (response.ok) {
      getFile(filePath, fileType)
      setSelectedFile(null)
      setError(null)
    } else {
      const result = await response.text();
      setError(result || `${t("There was an error uploading the")} ${t(fileType)}`)
    }
  }

  const handleDelete = async () => {
    if (window.confirm(`${t("Are you sure you want to remove this")} ${t(fileType)}${t("?")}`)) {
      const response = await api.delete(filePath, null, history);
      if (response.ok) {
        setFile(null)
        setError(null)
      } else {
        const result = await response.text();
        setError(result || `${t("There was an error deleting the")} ${t(fileType)}`)
      }
      setFilePreview(null)
    }
  };

  const handleChange = event => {
    setSelectedFile(event.target.files[0])
    const fileUrl = URL.createObjectURL(event.target.files[0])
    setFilePreview(fileUrl)
  }

  const handleCancel = () => {
    setSelectedFile(null)
    setFilePreview(null)
    setError(null)
  }

  return (
    <>
      <div className={styles.file_container}>
        <Typography variant="subtitle1">{t(title)}</Typography>
        {
          error &&
          <Typography className={styles.error} variant="body1">
            {error}
          </Typography>
        }
        {
          (file || filePreview) &&
          fileType === "image" &&
          <img
            className={styles.image}
            src={filePreview || file}
            alt={title}
          />
        }
        {
          file &&
          fileType !== "image" &&
          <a
            className={styles.config_link}
            href={file}
            download={fileName}
          >
              {t(downloadLabel)}
          </a>
        }
        {
          !selectedFile &&
          <div className={styles.button_grid}>
            <div>
              <input
                accept={inputType}
                id={inputId}
                type="file"
                className={styles.input}
                onChange={handleChange}
              />
              <label htmlFor={inputId}>
                <Button
                  color="primary"
                  component="span"
                  variant="contained"
                  className={styles.button}
                  fullWidth
                >
                  {file ? `${t("Choose new")} ${t(fileType)}` : `${t("Choose")} ${t(fileType)}`}
                </Button>
              </label>
            </div>
            {
              file ?
                <Button
                  onClick={handleDelete}
                  color="default"
                  variant="contained"
                  fullWidth
                >
                  {t("Remove")}
                </Button> :
                <span />

            }
          </div>
        }
        {
          selectedFile &&
          <>
            <Typography className={styles.file_name} variant="subtitle1">
              {selectedFile.name}
            </Typography>
            <div className={styles.button_grid}>
              <Button
                onClick={handleCancel}
                color="default"
                variant="contained"
                fullWidth
              >
                Cancel
              </Button>
              <Button
                onClick={handleUpload}
                color="primary"
                variant="contained"
                fullWidth
              >
                Upload
              </Button>
            </div>
          </>
        }
      </div>
    </>
  );
};

FileUploader.propTypes = {
  fileType: PropTypes.oneOf(["image", "file"]).isRequired,
  filePath: PropTypes.string.isRequired,
  fileName: PropTypes.string,
  inputType: PropTypes.string,
  inputId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  downloadLabel: PropTypes.string,
  validate: PropTypes.func
};

export default FileUploader;
