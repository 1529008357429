import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import styles from "./PageSidebar.module.css";

const PageSidebar = props => (
  <div className={`${styles.pageSidebar} ${props.className || ""}`}>
    {props.children}
  </div>
);

PageSidebar.propTypes = { children: PropTypes.any };

export default withRouter(PageSidebar);
