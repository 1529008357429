import React, { useContext, useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import ReactToPrint from "react-to-print";
import ArrowBack from "@material-ui/icons/ArrowBack";
import ArrowDown from "@material-ui/icons/ArrowDownward";
import ArrowUp from "@material-ui/icons/ArrowUpward";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import PersonAddOutlined from "@material-ui/icons/PersonAddOutlined";
import Lens from "@material-ui/icons/Lens";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import api from "../utils/api";
import { useApiPost } from "../utils/useApi";
import { getTime } from "../utils/helpers";
import styles from "./SessionDetails.module.css";
import scanEfficiencyImageUrl from "./efficiency.svg";
import timeImageUrl from "./time.svg";
import workloadImageUrl from "./workload.svg";
import PageSidebar from "../shared/layout/PageSidebar/PageSidebar";
import SessionVideos from "./SessionVideos";
import Spinner from "../shared/Spinner";
import LandscapeOrientation from "../shared/layout/LandscapeOrientation";
import { ResponsiveContext } from '../shared/layout/ResponsiveContext';
import { useTranslation } from 'react-i18next';

const Algorithms = ["Raw", "Sum"];

const pageTitle = document.title;

const getChartOptions = (lines, t) => {
  return {
    chart: {
      styledMode: true,
      zoomType: "x"
    },
    title: {
      text: ""
    },
    xAxis: {
      title: {
        text: t("Time")
      }
    },
    yAxis: {
      title: {
        text: t("Workload")
      }
    },
    series: lines.map(line => ({
      type: "line",
      name: line.name,
      data: line.data
    }))
  };
};

const sessionColorClasses = [
  styles.chartColor0,
  styles.chartColor1,
  styles.chartColor2,
  styles.chartColor3,
  styles.chartColor4,
  styles.chartColor5,
  styles.chartColor6,
  styles.chartColor7,
  styles.chartColor8,
  styles.chartColor9
];

const SessionColorIcon = ({ className }) => (
  <Lens className={`${className} ${styles.chartColorIcon}`} />
);

const PersonDetails = ({ goBack, sessions, isTablet }) => {
  const { t } = useTranslation();

  let lastSubjectId = null;
  let colorIndex = null;

  const sortedSubjects = sessions.sort((a, b) => {
    if (a.name === b.name) { return b.date - a.date; }
    return a.name > b.name ? 1 : -1;
   });

  return (
    <PageSidebar
      className={
        classNames(
          styles.personDetails,
          'sidebar',
          {[styles.person_details_mobile]: isTablet}
        )
      }
    >
      <div className={
        classNames(
          styles.personDetailsHeader,
          'hide_print',
          {[styles.header_mobile]: isTablet}
        )
      }>
        <IconButton
          classes={{
            root: classNames(styles.backButton, {[styles.back_mobile]: isTablet})
          }}
          color="inherit"
          aria-label="Menu"
          onClick={goBack}
        >
          <ArrowBack />
        </IconButton>
        <Typography color="inherit" variant="h4">
          {t("Sessions")}
        </Typography>
      </div>
      <div>
        {sortedSubjects.map((session, index) => {
          const newSubject = lastSubjectId !== session.subjectId;
          lastSubjectId = session.subjectId;
          colorIndex =
            colorIndex === null ? 0 : colorIndex === 9 ? 0 : colorIndex + 1;
          const colorClass = sessionColorClasses[colorIndex];
          return (
            <div className={`${styles.personDetail} avoid_page_break`} key={session.resultId}>
              {newSubject && (
                <>
                  {
                    newSubject && index !== 0 &&
                    <div className={isTablet ? styles.spacer_mobile : styles.spacer} />
                  }
                  <Typography
                    classes={{ root: styles.subjectName }}
                    color="inherit"
                  >
                    <Icon className="subject_icon" color="inherit" size="small">
                      <PersonAddOutlined />
                    </Icon>
                    <span className="subject_name">
                      {` ${session.name}`}
                    </span>
                  </Typography>
                  <Typography
                    className={`${styles.light} ${styles.rank} rank`}
                    variant="caption"
                    variantMapping={{ caption: "p" }}
                  >
                    {session.rankName}
                  </Typography>
                  <Typography
                    className={`${styles.light} ${styles.uniqueId} unique_id`}
                    variant="caption"
                    variantMapping={{ caption: "p" }}
                  >
                    {session.uniqueId}
                  </Typography>
                </>
              )}
              <div className={styles.testNameWrapper}>
                <SessionColorIcon className={colorClass} />
                <Typography
                  className={`${styles.title} test_name`}
                  variant="caption"
                  variantMapping={{ caption: "p" }}
                >
                  {session.testName}
                </Typography>
              </div>
              <Typography
                className={`${styles.light} ${styles.indent} platform`}
                variant="caption"
                variantMapping={{ caption: "p" }}
              >
                {session.platformName}
              </Typography>
              <Typography
                className={`${styles.light} ${styles.indent} date`}
                variant="caption"
                variantMapping={{ caption: "p" }}
              >
                {session.dateString}
              </Typography>
            </div>
          );
        })}
      </div>
    </PageSidebar>
  );
};

const getMetricClassName = value =>
  value === 0
    ? styles.metricValueSame
    : value < 0
    ? styles.metricValueDown
    : styles.metricValueUp;

const MetricIcon = ({ value }) => {
  const val = Math.round(value);
  const className = getMetricClassName(val);
  return val === 0 ? (
    <div />
  ) : val < 0 ? (
    <ArrowDown className={`${styles.metricIcon} ${className}`} />
  ) : (
    <ArrowUp className={`${styles.metricIcon} ${className}`} />
  );
};

const MetricValue = ({ value }) => {
  const val =  Math.abs(Math.round(value));
  const className = getMetricClassName(value);
  return <span className={`${styles.metricValue} ${className}`}>{val}%</span>;
};

const MetricImage = ({ imageUrl }) => {
  return (
    <div className={`${styles.metricImageWrapper} hide_print`}>
      <div className={styles.metricImageBackground} />
      <img className={`${styles.metricImage} hide_print`} src={imageUrl} alt="Metric" />
    </div>
  );
};

const Highlights = props => {
  const {
    options,
    isMobile
  } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const [workloadImprovement] = useApiPost(
    "Statistics/WorkloadImprovement",
    options,
    history
  );
  const [timeImprovement] = useApiPost(
    "Statistics/TimeImprovement",
    options,
    history
  );
  const [scanEfficiencyImprovement] = useApiPost(
    "Statistics/ScanEfficiencyImprovement",
    options,
    history
  );
  const { data: workload, loading: workloadLoading } = workloadImprovement;
  const { data: time, loading: timeLoading } = timeImprovement;
  const {
    data: scanEfficiency,
    loading: scanEfficiencyLoading
  } = scanEfficiencyImprovement;
  const loading = workloadLoading && timeLoading && scanEfficiencyLoading;

  return loading ? null : (
    <div className={
      classNames(
        styles.card,
        styles.highlights,
        'card',
        {[styles.card_mobile]: isMobile}
      )
    }>
      <Typography className={styles.title} variant="h5">
        {t("Performance")}
      </Typography>
      <div className={styles.spacer} />
      <div className={
        classNames(
          styles.cardContent,
          styles.highlightsMetrics,
          'highlights_metric',
          {[styles.metrics_mobile]: isMobile}
        )
      }>
        <div className={`${styles.highlightsMetric} metric`}>
          <Typography
            className={styles.metricTitle}
            color="inherit"
            variant="body1"
          >
            {t("Workload")}
          </Typography>
          <div className={styles.metricValueWrapper}>
            <MetricIcon value={workload} />
            <MetricValue value={workload} />
          </div>
          <MetricImage imageUrl={workloadImageUrl} />
        </div>
        <div className={`${styles.highlightsMetric} metric`}>
          <Typography
            className={styles.metricTitle}
            color="inherit"
            variant="body1"
          >
            {t("Time")}
          </Typography>
          <div className={styles.metricValueWrapper}>
            <MetricIcon value={time} />
            <MetricValue value={time} />
          </div>
          <MetricImage imageUrl={timeImageUrl} />
        </div>
        <div className={`${styles.highlightsMetric} metric`}>
          <Typography
            className={styles.metricTitle}
            color="inherit"
            variant="body1"
          >
            {t("Scan Efficiency")}
          </Typography>
          <div className={styles.metricValueWrapper}>
            <MetricIcon value={scanEfficiency} />
            <MetricValue value={scanEfficiency} />
          </div>
          <MetricImage imageUrl={scanEfficiencyImageUrl} />
        </div>
      </div>
    </div>
  );
};

const Chart = ({ algorithm, chartOptions, setAlgorithm, isMobile }) => {
  const { t } = useTranslation();

  return (
    <div className={
      classNames(
        styles.card,
        styles.chartCard,
        'avoid_page_break',
        {[styles.card_mobile]: isMobile}
      )
    }>
      <div className={styles.cardContent}>
        <div className={styles.chartHeading}>
          <Typography className={styles.title} variant="h5">
            {t("Results")}
          </Typography>
          <Select
            className={`${styles.select} select`}
            value={algorithm}
            onChange={e => setAlgorithm(e.target.value)}
          >
            {Algorithms.map(alg => (
              <MenuItem key={alg} value={alg} className={styles.menuItem}>
                {t(alg)}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className="avoid_page_break">
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </div>
      </div>
    </div>
  );
};

const SessionDetails = props => {
  const {
    goBack,
    sessions,
  } = props;
  const { t } = useTranslation();
  const options = { body: JSON.stringify(sessions.map(s => s.resultId)) };
  const [results, setResults] = useState(undefined);
  const [algorithm, setAlgorithm] = useState("Sum");
  const [chartOptions, setChartOptions] = useState(getChartOptions([], t));
  let history = useHistory();
  const printRef = useRef(null);
  const {isMobile, isTablet} = useContext(ResponsiveContext);

  useEffect(() => {
    if (results) {
      let lines = [];

      let processResults = null;

      if (algorithm === "Sum") {
        processResults = resultData => {
          var data = [];
          resultData.reduce((a, b, i) => {
            return (data[i] = +(a + b).toFixed(2));
          }, 0);
          return data;
        };
      } else {
        processResults = resultData => resultData;
      }

      results.forEach((result, i) => {
        let session = result.session,
          resultData = result.resultData.map(
            d => (Math.max(0, d.leftValue) + Math.max(d.rightValue, 0)) / 2
          ),
          data = processResults(resultData);

        lines.push({
          name: session.testName,
          data
        });
      });

      setChartOptions(getChartOptions(lines, t));
    }
  }, [results, t, algorithm]);

  useEffect(() => {
    Promise.all(
      sessions.map(async session => {
        const processData = resultData => {
          return {
            session,
            resultData
          };
        };

        let data = await api.get(
          `result/workloaddata/${session.resultId}`,
          undefined,
          history
        );

        return processData(data);
      })
    ).then(res => {
      setResults(res);
    });
  }, [sessions, history]);

  return results ? (
    <div
      className={
        classNames(
          styles.sessionDetails,
          'session_details_page',
          {[styles.session_details_mobile]: isTablet}
        )
      }
      ref={printRef}
    >
      <LandscapeOrientation />
      <PersonDetails {...{ goBack, sessions, isTablet }} />
      <div>
        <ReactToPrint
          trigger={
            () => (<Button
              color="primary"
              variant="contained"
              className={`${styles.print_button} hide_print`}
            >
              {t("Print")}
            </Button>)
          }
          content={() => printRef.current}
          onBeforePrint={() => (document.title = `${getTime()} Session Details`)}
          onAfterPrint={() => (document.title = pageTitle)}
        />
        <Highlights {...{ options, isMobile }} />
        <Chart {...{ algorithm, chartOptions, setAlgorithm, isMobile }} />
        {(sessions || []).map((session, index) =>
          <SessionVideos {...{session, key: index}} />
        )}
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default SessionDetails;
