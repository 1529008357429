import React, { useState, useCallback } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import dateFns from "@date-io/date-fns";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PrivateRoute from "./shared/PrivateRoute";
import Login from "./login/Login";
import RequestResetPassword from "./login/RequestResetPassword";
import ResetPassword from "./login/ResetPassword";
import Main from "./main/Main";
import "typeface-roboto";
import styles from "./App.module.css";
import { logoutUser } from "./utils/user";
import { I18nextProvider } from "react-i18next";
import i18n, { handleLanguageChange } from "./i18n";

const muiTheme = createMuiTheme({
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "sans-serif"
    ].join(",")
  },
  palette: {
    primary: {
      light: "#69b6ff",
      main: "#1c87e5",
      dark: "#005bb2",
      contrastText: "#fff"
    },
    secondary: {
      light: "#ff94c2",
      main: "#f06292",
      dark: "#ba2d65",
      contrastText: "#fff"
    }
  }
});

const stripePublicKey =
  process.env.REACT_APP_STRIPE_PUBLIC_KEY ||
  "pk_live_41XRT68fG98O7q60Csee26LalkkpJAuQvwikkRamkfkeZBXMtxqLZ7iYD6dUveW2MYZiy1f17cGQN4gD1WnwrWTxr00KTzQPkW8";
const stripePromise = loadStripe(stripePublicKey);

const App = () => {
  const [user, setUser] = useState(() => {
    let value = localStorage.getItem("user");
    if (value !== "undefined" && value) {
      const jsonUser = JSON.parse(value);
      handleLanguageChange(jsonUser.language, [], true);
      return jsonUser;
    }
    return undefined;
  });

  const onLogin = useCallback(loggedInUser => {
    setUser(loggedInUser);
    handleLanguageChange(loggedInUser.language, [], true);
  }, []);

  const onLogout = useCallback(() => {
    logoutUser();
    setUser(undefined);
    localStorage.removeItem("user");
    localStorage.removeItem("deviceId");
  }, []);

  return (
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <MuiThemeProvider theme={muiTheme}>
          <MuiPickersUtilsProvider utils={dateFns}>
            <Elements stripe={stripePromise}>
              <div className={styles.App}>
                <Switch>
                  <Route path="/login/:origin?">
                    <Login onLogin={onLogin} />
                  </Route>
                  <Route path="/request-password-reset">
                    <RequestResetPassword />
                  </Route>
                  <Route path="/reset-password/:code">
                    <ResetPassword />
                  </Route>
                  <PrivateRoute path="/" user={user}>
                    <Main {...{ user, setUser, onLogout }} />
                  </PrivateRoute>
                </Switch>
              </div>
            </Elements>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </I18nextProvider>
    </BrowserRouter>
  );
};

export default App;
