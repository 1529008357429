import React from "react";
import { Field } from "formik";
import TextField from "../../shared/forms/controls/TextField";
import { useTranslation } from 'react-i18next';
import FieldGroup from "../../shared/forms/layout/FieldGroup";

export default () => {
  const { t } = useTranslation();

  return (
    <>
      <FieldGroup>
        <Field
          component={TextField}
          label={t("Name")}
          name="name"
          variant="standard"
        />
        <Field
          component={TextField}
          label={t("Description")}
          name="description"
          variant="standard"
          multiline
        />
      </FieldGroup>
    </>
  );
};
