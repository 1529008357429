import React from "react";
import { Field } from "formik";
import * as yup from "yup";
import TextField from "../../controls/TextField";
import { useTranslation } from 'react-i18next';

export const initialValues = {
  firstName: "",
  lastName: ""
};

const validation = {
  firstName: yup
    .string()
    .label("First Name")
    .typeError("First Name must be a string"),
  lastName: yup
    .string()
    .label("Last Name")
    .typeError("Last Name must be a string")
};

export const requiredValidationSchema = {
  firstName: validation.firstName.required(),
  lastName: validation.lastName.required()
};

export const validationSchema = {
  firstName: validation.firstName.nullable(),
  lastName: validation.lastName.nullable()
};

export default () => {
  const { t } = useTranslation();

  return (
    <>
      <Field
        component={TextField}
        label={t("First Name")}
        name="firstName"
        variant="standard"
      />
      <Field
        component={TextField}
        label={t("Last Name")}
        name="lastName"
        variant="standard"
      />
    </>
  );
}
