import React from "react";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from 'react-i18next';
import styles from "./styles.module.css";
import UnauthenticatedLayout from "../shared/layout/UnauthenticatedLayout";

export default () => {
  const { t } = useTranslation();

  return (
    <UnauthenticatedLayout>
      <div className={styles.wrapper}>
        <Typography align="left" variant="h6">
          {t("Page Not Found.")}
        </Typography>
        <Typography align="left" variant="body">
          {t("We're sorry. We could not find the page you were looking for.")}
        </Typography>
      </div>
    </UnauthenticatedLayout>
  );
};
