import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { ResponsiveContext } from './ResponsiveContext';
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import AssessmentOutlined from "@material-ui/icons/AssessmentOutlined";
import PersonAddOutlined from "@material-ui/icons/PersonAddOutlined";
import MenuIcon from  "@material-ui/icons/Menu";
import Close from "@material-ui/icons/Close";
import Help from "@material-ui/icons/Help";
import Settings from "@material-ui/icons/Settings";
import Videocam from "@material-ui/icons/Videocam";
import UserIconWMenu from "../menus/UserIconWMenu";
import styles from "./GlobalNav.module.css";
import whiteLogo from "../../assets/images/logo-white.svg";
import { useTranslation } from 'react-i18next';

const NavButton = props => {
  const { IconComponent, label, route, setOpen } = props;
  const history = useHistory();
  const path = window.location.pathname;
  const active = path.indexOf(route) !== -1;

  const handleClick = route => {
    setOpen(false)
    history.push(route)
  }

  return (
    <span
      className={`${styles.icon_wrapper} ${active ? styles.active_page : ""}`}
      onClick={() => handleClick(route)}
    >
      <div>
        <IconButton
          aria-label={label}
          className={styles.icon_button}
          disableRipple
        >
          <IconComponent className={styles.icon} />
        </IconButton>
        <div className={styles.icon_label}>{label}</div>
      </div>
    </span>
  );
};

const NavDrawer = props => {
  const { signout, user, setOpen, isTablet } = props;
  const { t } = useTranslation();

  return(
    <Drawer
      className={`${styles.drawer} ${isTablet && styles.mobile_drawer}`}
      classes={{
        root: styles.drawerRoot,
        paperAnchorDockedLeft: styles.drawerAnchor
      }}
      variant="persistent"
      anchor="left"
      open={true}
    >
      <div className={styles.nav_buttons}>
        <NavButton
          {...{
            IconComponent: Videocam,
            label: t("Sessions"),
            route: `/${t("sessions")}`,
            setOpen
          }}
        />
        <NavButton
          {...{
            IconComponent: PersonAddOutlined,
            label: t("Subjects"),
            route: `/${t("subjects")}`,
            setOpen
          }}
        />
        <NavButton
          {...{
            IconComponent: AssessmentOutlined,
            label: t("Reports"),
            route: `/${t("reports")}`,
            setOpen
          }}
        />
        <NavButton
          {...{
            IconComponent: Settings,
            label: t("Configure"),
            route: `/${t("configs")}`,
            setOpen
          }}
        />
        <span />
        <NavButton
          {...{
            IconComponent: Help,
            label: t("Tutorials"),
            route: `/${t("tutorials")}`,
            setOpen
          }}
        />
        {
          !isTablet &&
          <div className={styles.logout}>
            <UserIconWMenu {...{ signout, user }} />
          </div>
        }
      </div>
    </Drawer>
  )
}

const GlobalNav = props => {
  const { signout, user } = props;
  const [open, setOpen] = useState(false);
  const {isMobile, isTablet} = useContext(ResponsiveContext);

  const Icon = open ? Close : MenuIcon;

  return (
    (isMobile || isTablet) ?
      <>
        <div className={styles.mobile_nav}>
          <Icon onClick={() => setOpen(!open)} style={{width: 25, cursor: 'pointer'}}/>
          <img alt="logo" className={styles.logo} src={whiteLogo} />
          <UserIconWMenu {...{ signout, user, isTablet }} />
        </div>
        {
          open &&
          <NavDrawer {...{signout, user, isMobile, isTablet, setOpen}} />
        }
      </> :
      <NavDrawer {...{signout, user, isMobile, isTablet, setOpen}} />
  );
};

GlobalNav.propTypes = {
  signout: PropTypes.func.isRequired,
  user: PropTypes.object
};
export default GlobalNav;
