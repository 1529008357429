import React from "react";
import * as yup from "yup";
import { emptyValuesToNull } from "../../shared/forms/fields/transforms";
import EntityMangementPage from "../../shared/EntityMangementPage";
import FormComponent from "./TestForm";

const columns = [
  { name: "Name", alignment: "left", dataName: "name", sortName: "Name" },
  { name: "Description", alignment: "left", dataName: "description" }
];
const dataUrl = "test";
const entityName = "Test";
const initialValues = { description: "", name: "" };
const validationSchema = yup.object().shape({
  description: yup
    .string()
    .nullable()
    .typeError("Description must be a string"),
  name: yup
    .string()
    .required()
    .label("Name")
    .typeError("Name must be a string")
});
const transformValues = emptyValuesToNull;

const TestsPage = ({ user }) => (
  <EntityMangementPage
    {...{
      columns,
      dataUrl,
      entityName,
      FormComponent,
      initialValues,
      transformValues,
      validationSchema,
      sortColumn: "Name"
    }}
  />
);

export default TestsPage;
