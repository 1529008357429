import React from "react";
import { Field } from "formik";
import * as yup from "yup";
import { useTranslation } from 'react-i18next';
import TextField from "../../controls/TextField";
import CountrySelect from "../../controls/CountrySelect";
import StateSelect from "../../controls/StateSelect";
import InlineFields from "../../layout/InlineFields";

export const initialValues = {
  address1: "",
  address2: "",
  address3: "",
  city: "",
  country: "",
  state: "",
  zip: ""
};

export const validationSchema = {
  address1: yup
    .string()
    .nullable()
    .typeError("Address1 must be a string"),
  address2: yup
    .string()
    .nullable()
    .typeError("Address2 must be a string"),
  address3: yup
    .string()
    .nullable()
    .typeError("Address3 must be a string"),
  city: yup
    .string()
    .nullable()
    .typeError("City must be a string"),
  country: yup
    .string()
    .nullable()
    .typeError("Country must be a string"),
  state: yup
    .string()
    .nullable()
    .typeError("State must be a string"),
  zip: yup
    .number()
    .nullable()
    .typeError("Postal Code must be a number")
};

export default () => {
  const { t } = useTranslation();

  return (
    <>
      <Field
        component={TextField}
        label={t("Address 1")}
        name="address1"
        variant="standard"
      />
      <Field
        component={TextField}
        label={t("Address 2")}
        name="address2"
        variant="standard"
      />
      <Field
        component={TextField}
        label={t("Address 3")}
        name="address3"
        variant="standard"
      />
      <InlineFields>
        <Field
          component={TextField}
          label={t("City")}
          name="city"
          variant="standard"
        />
        <Field
          component={StateSelect}
          label={t("State / Province")}
          name="state"
          variant="standard"
        />
      </InlineFields>
      <InlineFields>
        <Field
          component={TextField}
          label={t("Postal Code")}
          name="zip"
          variant="standard"
        />
        <Field
          component={CountrySelect}
          label={t("Country")}
          name="country"
          variant="standard"
        />
      </InlineFields>
    </>
  );
}
