import React, { useCallback } from "react";
import * as yup from "yup";
import { useHistory } from "react-router-dom";
import { Formik, Form } from "formik";
import { useTranslation } from 'react-i18next';
import Typography from "@material-ui/core/Typography";
import api from "../utils/api";
import UnauthenticatedLayout from "../shared/layout/UnauthenticatedLayout";
import FieldGroup from "../shared/forms/layout/FieldGroup";
import styles from "./RequestResetPassword.module.css";
import SubmitButtonWithProgress from "../shared/forms/controls/SubmitButtonWithProgress";
import EmailField, {
  initialValues,
  validationSchema as emailValidationSchema
} from "../shared/forms/fields/EmailField";

const validationSchema = yup.object().shape({
  ...emailValidationSchema
});

export default () => {
  const history = useHistory();
  const { t } = useTranslation();

  const onSubmit = useCallback(
    async (values, { setStatus }) =>
      new Promise(async resolve => {
        const genericErrorStatus = {
          successful: false,
          error: "Something went wrong. Please try again"
        };
        try {
          const response = await api.post(
            "RequestPasswordReset",
            {
              body: JSON.stringify({ username: values.newEmail })
            },
            history
          );

          if (typeof response.ok !== "undefined") {
            if (response.ok) setStatus({ successful: true, error: null });
            else setStatus(genericErrorStatus);
          } else {
            setStatus(genericErrorStatus);
          }

          resolve();
        } catch (err) {
          setStatus(genericErrorStatus);
          resolve();
        }
      }),
    [history]
  );

  return (
    <UnauthenticatedLayout>
      <Formik {...{ initialValues, onSubmit, validationSchema }}>
        {form => {
          const {
            isSubmitting,
            status,
            submitForm,
            values: { newEmail }
          } = form;
          const { error, successful } = status || {};

          const onKeyPress = useCallback(
            e => {
              if (e.key === "Enter") submitForm();
            },
            [submitForm]
          );
          return (
            <Form>
              <FieldGroup>
                <div className={`${styles.textWrapper}`}>
                  {successful ? (
                    <>
                      <Typography variant="subtitle1">
                        {t("Your request has been sent.")}
                      </Typography>
                      <Typography variant="body2">
                        {`${t("If an account exists associated with your email address,")} `}
                        <span className={styles.email}>{newEmail}</span>{`, `}
                        {t("an email containing a link to reset your password will be sent to you.")}
                      </Typography>
                      <Typography variant="body2">
                        {t("Didn't get an email? Request a new link below.")}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography variant="body1">
                        {t("Enter your email address to request a password reset link.")}
                      </Typography>
                      {error && (
                        <Typography className={styles.error} variant="body1">
                          {t(error)}
                        </Typography>
                      )}
                    </>
                  )}
                </div>
                <EmailField
                  className={successful ? styles.hidden : null}
                  label={t("Enter email")}
                  onKeyPress={onKeyPress}
                  variant="filled"
                  InputLabelProps={{style: { color: 'white'}}}
                />
                <SubmitButtonWithProgress
                  color="primary"
                  fullWidth
                  isSubmitting={isSubmitting}
                  submitForm={submitForm}
                  text={successful ? "Request New Link" : "Request Link"}
                  variant="contained"
                />
              </FieldGroup>
            </Form>
          );
        }}
      </Formik>
    </UnauthenticatedLayout>
  );
};
