import React from "react";
import { useTranslation } from 'react-i18next';
import FileUploader from "../../shared/forms/controls/FileUploader";

const validFileTypes = ['image/bmp', 'image/gif', 'image/jpeg', 'image/png', 'image/tiff']

export default props => {
  const { platformId } = props;
  const filePath = `PlatformImage/${platformId}`
  const { t } = useTranslation();

  const validate = (file) => {
    const fileSize = file.size / 1024 / 1024; // in MB
    if (fileSize > 3) return t('The file must be less than 3 MB')

    const validType = validFileTypes.includes(file.type)
    if (!validType) return t('The file must be a jpg, png, bmp, gif, or tiff')

    return null;
  }

  return (
    <FileUploader
      {...{
        title: 'Platform Image',
        fileType: 'image',
        filePath,
        inputType: "image/*",
        inputId: "platformImage",
        validate
      }}
    />
  );
};
