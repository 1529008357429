import React, { useContext } from "react";
import classNames from "classnames";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import PageSidebar from "../../shared/layout/PageSidebar/PageSidebar";
import SessionsFilters from "./SessionsFilters";
import SessionsTable from "./SessionsTable";
import Pagination from "../../shared/layout/Pagination";
import Spinner from "../../shared/Spinner";
import NoResults from "../../shared/NoResults";
import { ResponsiveContext } from '../../shared/layout/ResponsiveContext';
import { useTranslation } from 'react-i18next';
import styles from "./SessionsSelect.module.css";

const SessionsDisplay = props => {
  const {
    sessionData,
    sessions,
    totalResults,
    loading,
    filters,
    checked,
    anyChecked,
    handleFilterChange,
    handleOpenFilter,
    handleCloseFilter,
    handleClearFilters,
    handlePageChange,
    handlePerPageChange,
    onClickCompare,
    onSelectSessions,
    setCheckedResult,
    toggleAllChecked
  } = props;
  const {isTablet} = useContext(ResponsiveContext);
  const { t } = useTranslation();

  return (
    <div className={classNames(styles.sessions, {[styles.sessions_mobile]: isTablet})}>
      {sessionData && (
        <>
          <PageSidebar className={
            classNames(
              styles.sidebar,
              {[styles.sidebar_mobile]: isTablet}
            )
          }>
            <div>
              <Typography variant="h4">{t("Sessions")}</Typography>
              <SessionsFilters
                {...{
                  filters,
                  handleFilterChange,
                  handleOpenFilter,
                  handleCloseFilter,
                  handleClearFilters,
                }}
                subjects={filters["Subjects"] || []}
                ranks={filters["Ranks"] || []}
                platforms={filters["Platforms"] || []}
                tests={filters["Tests"] || []}
                startDate={filters["StartDate"]}
                endDate={filters["EndDate"]}
              />
            </div>
          </PageSidebar>
          {
            sessions && sessions.length === 0 &&
            <NoResults
              messages={[
                t("Sorry, that filter combination has no results."),
                t("Adjust your search and try again.")
              ]}
            />
          }
          {
            sessions && sessions.length > 0 &&
            <div className={classNames(
              styles.table_wrapper,
              {[styles.table_wrapper_mobile]: isTablet})
            }>
              <div className={classNames(
                styles.table_actions,
                {[styles.table_actions_mobile]: isTablet})
              }>
                <FormControlLabel
                  control={
                    <Checkbox
                      classes={{checked: styles.checkboxChecked, root: styles.checkboxRoot}}
                      color="primary"
                      checked={anyChecked}
                      onChange={toggleAllChecked}
                    />
                  }
                  label={anyChecked ? t("Deselect All") : t("Select All")}
                  labelPlacement="start"
                />
                <span/>
                { anyChecked &&
                  <Button
                    className={styles.compare}
                    color="primary"
                    onClick={onClickCompare}
                    variant="contained"
                  >
                    <Typography variant="body1">{t("Compare")}</Typography>
                  </Button>
                }
              </div>
              <SessionsTable
                {...{
                  sessions,
                  checked,
                  setCheckedResult,
                  onSelectSessions,
                  handleFilterChange
                }}
                sortColumn={filters["SortColumn"]}
                sortDirection={filters["SortDirection"]}
              />
              <Pagination
                {...{
                  handlePageChange,
                  handlePerPageChange,
                  totalResults
                }}
                activePage={filters["PageNumber"]}
                resultsPerPage={filters["RowsPerPage"]}
              />
            </div>
          }
        </>
      )}
      { loading && <Spinner /> }
    </div>
  );
};

export default SessionsDisplay;
