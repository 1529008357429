import React from "react";
import { Field } from "formik";
import { useTranslation } from 'react-i18next';
import DatePicker from "../shared/forms/controls/DatePicker";
import FieldGroup from "../shared/forms/layout/FieldGroup";
import InlineFields from "../shared/forms/layout/InlineFields";
import AddressFields from "../shared/forms/fields/AddressFields";
import EmailField from "../shared/forms/fields/EmailField";
import GenderField from "../shared/forms/fields/GenderField";
import PersonNameFields from "../shared/forms/fields/PersonNameFields";
import PhoneNumberFields from "../shared/forms/fields/PhoneNumberFields";
import RankField from "../shared/forms/fields/RankField";
import UniqueIdField from "../shared/forms/fields/UniqueIdField";

const SubjectForm = props => {
  const {
    form: { initialValues }
  } = props;
  const { uniqueId: initialUniqueId } = initialValues;
  const { t } = useTranslation();

  return (
    <>
      <FieldGroup light>
        <InlineFields>
          <PersonNameFields />
        </InlineFields>
        <InlineFields>
          <Field
            component={DatePicker}
            disableFuture={true}
            format={"MMM D, YYYY"}
            inputVariant="standard"
            label={t("Date of Birth")}
            name="dateOfBirth"
            openTo="year"
            views={["year", "month", "date"]}
          />
          <GenderField />
        </InlineFields>
        <RankField />
        <UniqueIdField {...{ initialValue: initialUniqueId }} />
      </FieldGroup>
      <FieldGroup>
        <AddressFields />
      </FieldGroup>
      <FieldGroup light>
        <PhoneNumberFields />
        <EmailField />
      </FieldGroup>
    </>
  );
};

export default SubjectForm;
