import { useCallback, useState } from "react";

export default function useStepper(maxSteps = 0, initialStep = 0) {
  const [activeStep, setActiveStep] = useState(initialStep);
  const nextStep = useCallback(() => {
    setActiveStep(previousActiveStep => {
      // let the max step go one higher so we can show a finish state
      const lastStep = maxSteps;
      const nextStep = previousActiveStep + 1;
      return nextStep > lastStep ? lastStep : nextStep;
    });
  }, [maxSteps]);
  const previousStep = useCallback(() => {
    setActiveStep(previousActiveStep => {
      const nextStep = previousActiveStep - 1;
      return nextStep >= 0 ? nextStep : 0;
    });
  }, []);
  const reset = useCallback(() => {
    setActiveStep(0);
  }, []);

  return [activeStep, { nextStep, previousStep, reset }];
}
