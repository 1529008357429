import React, { useContext } from "react";
import classNames from "classnames";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import DatePicker from "../../shared/forms/controls/DatePicker";
import PlatformSelect from "../../shared/forms/controls/PlatformSelect";
import SubjectSelect from "../../shared/forms/controls/SubjectSelect";
import TestSelect from "../../shared/forms/controls/TestSelect";
import RankSelect from "../../shared/forms/controls/RankSelect";
import { ResponsiveContext } from '../../shared/layout/ResponsiveContext';
import styles from "./SessionsSelect.module.css";
import { useTranslation } from 'react-i18next';

const addSelect = (name, currentValue, handleChange, handleOpenFilter, handleCloseFilter, Component, t) => {
  return (
    <div className={styles.multiselect}>
      <Component
        name={name}
        variant="standard"
        isMulti
        label={t(name)}
        placeholder={t("All")}
        field={{name}}
        form={{errors: {}, touched: {}}}
        closeMenuOnSelect={false}
        blurInputOnSelect={false}
        hideSelectedOptions={true}
        value={currentValue}
        onChange={(value, {action}) => handleChange(name, value, action)}
        onMenuOpen={handleOpenFilter}
        onMenuClose={handleCloseFilter}
      />
    </div>
  );
};

const dateSelect = (name, currentValue, handleChange, handleOpenFilter, handleCloseFilter, t) => {
  const onChange = (date, x, y) => {
    const value = date ? date._d.toDateString() : null;
    const filter = name.replace(/\s/g, "");
    handleChange(filter, value)
  };

  const value = currentValue ? Date.parse(currentValue) : null;

  return (
    <div className={styles.full_width}>
      <InputLabel className={styles.inputLabel} htmlFor={name}>
        {t(name)}
      </InputLabel>
      <DatePicker
        autoOk
        fullWidth
        className={styles.datepicker}
        clearable
        disableFuture
        format={"MMM D, YYYY"}
        inputVariant="standard"
        name="date"
        placeholder={t("All")}
        openTo="date"
        views={["year", "month", "date"]}
        value={value}
        onChange={onChange}
        onOpen={handleOpenFilter}
        onClose={handleCloseFilter}
      />
    </div>
  );
};

const SessionsFilters = props => {
  const {
    subjects,
    ranks,
    tests,
    platforms,
    startDate,
    endDate,
    handleFilterChange,
    handleOpenFilter,
    handleCloseFilter,
    handleClearFilters
  } = props;

  const { t } = useTranslation();
  const {isTablet, isMobile} = useContext(ResponsiveContext);
  const showClear =
    subjects.length > 0 ||
    ranks.length > 0 ||
    tests.length > 0 ||
    platforms.length > 0 ||
    startDate || endDate;

  return (
    <div
      className={classNames(
        styles.row,
        styles.headerRow,
        {
          [styles.tabletHeader]: isTablet,
          [styles.mobileHeader]: isMobile
        }
      )}
    >
      {addSelect("Subjects", subjects, handleFilterChange, handleOpenFilter, handleCloseFilter, SubjectSelect, t)}
      {addSelect("Ranks", ranks, handleFilterChange, handleOpenFilter, handleCloseFilter, RankSelect, t)}
      {addSelect("Platforms", platforms, handleFilterChange, handleOpenFilter, handleCloseFilter, PlatformSelect, t)}
      {addSelect("Tests", tests, handleFilterChange, handleOpenFilter, handleCloseFilter, TestSelect, t)}
      {dateSelect("Start Date", startDate, handleFilterChange, handleOpenFilter, handleCloseFilter, t)}
      {dateSelect("End Date", endDate, handleFilterChange, handleOpenFilter, handleCloseFilter, t)}
      {
        showClear &&
        <Button variant="contained" onClick={handleClearFilters}>
          {t("Clear filters")}
        </Button>
      }
    </div>
  );
};

export default SessionsFilters;
