import React from "react";
import AsyncSelect from "../AsyncSelect";

const RankSelect = props => {
  const loadPath = 'ranks';

  const formatOptions = (options) => (
    (options || []).map(option =>
      ({label: option.name, value: {id: option.id, name: option.name}}))
  );

  const getOptionValue = (option) => option.value.id;

  return (
    <AsyncSelect {...{ ...props, getOptionValue, formatOptions, loadPath }} />
  );
};

export default RankSelect;
