import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import { useTranslation } from 'react-i18next';
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";
import TextField from "../../shared/forms/controls/TextField";
import api from "../../utils/api";
import styles from "./Platforms.module.css";

export default props => {
  const {region, updateRegions, regionNames} = props;
  const history = useHistory();
  const [edit, setEdit] = useState(false);
  const { t } = useTranslation();

  const initialValues = { region: region.name };

  const onSubmit = async (values, {setErrors}) => {
    const {region: name} = values;
    const { platformId, id } = region;

    const options = { body: JSON.stringify({name, platformId, id}) };
    const updatedRegion = await api.put('PlatformRegion', options, history);

    if (updatedRegion && updatedRegion.id) {
      updateRegions()
      setEdit(false)
    } else {
      setErrors({region: 'Sorry but something went wrong'})
    }
  };

  const handleDelete = async (region) => {
    if (window.confirm(`${t("Are you sure you want to delete this")} ${t("region")}${t("?")}`)) {
      const { id } = region;
      await api.delete(`PlatformRegion/${id}`, null, history);
      updateRegions()
    }
  };

  const validate = values => {
    const errors = {};

    const uniqueRegions = regionNames.filter(value => value !== region.name);

    if (!values.region) {
      errors.region = 'Region name is required';
    } else if (uniqueRegions.includes(values.region)) {
      errors.region = 'Region name must be unique';
    }

    return errors;
  };

  return (
    <>
        <Formik {...{ initialValues, onSubmit, validate }}>
          {({ values, submitForm, isValid }) => (
            <div className={styles.region_item}>
              <Form>
                { edit ?
                  <Field
                    component={TextField}
                    name="region"
                    variant="standard"
                    placeholder={t("Region name")}
                    onBlur={submitForm}
                    fullWidth
                  /> :
                  <Typography className={styles.region_name} variant="body1">
                    {values.region}
                  </Typography>
                }
            </Form>
              <IconButton onClick={() => isValid && setEdit(!edit)}>
                { edit ? <Check /> : <Edit /> }
              </IconButton>
              <IconButton onClick={() => handleDelete(region)}>
                <Close />
              </IconButton>
            </div>
          )}
        </Formik>
    </>
  );
};
