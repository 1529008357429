import React, { useCallback, useState } from "react";
import { Link, useParams, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import api from "../utils/api";
import FieldGroup from "../shared/forms/layout/FieldGroup";
import UnauthenticatedLayout from "../shared/layout/UnauthenticatedLayout";
import styles from "./Login.module.css";
import { v4 as uuidv4 } from "uuid";

const Login = props => {
  const { history, onLogin } = props;
  const { origin } = useParams();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const { t } = useTranslation();

  const deviceId = uuidv4();

  const login = useCallback(async () => {
    const options = {
      body: JSON.stringify({
        Username: username,
        Password: password,
        DeviceId: deviceId
      })
    };

    let value = null;
    try {
      value = await api.post("authenticate", options, history);
    } catch (err) {
      setError(
        `${t("The login attempt failed.")}\n\n ${t(
          "Please double check your email and password."
        )}\n\n`
      );
    }

    if (value) {
      localStorage.setItem("user", JSON.stringify(value));
      localStorage.setItem("deviceId", JSON.stringify(deviceId));

      const customer = await api.get(
        `customer/${value.customerId}`,
        null,
        history
      );
      if (customer) {
        value.customer = customer;
        localStorage.setItem("user", JSON.stringify(value));
      }
      onLogin(value);
      history.push(`/${t("sessions")}`);
    }
  }, [onLogin, username, password, t, history, deviceId]);

  const onKeyPress = useCallback(
    e => {
      if (e.key === "Enter") login();
    },
    [login]
  );
  const originMessage = origin
    ? origin === "reset"
      ? "Your password was reset successfully."
      : ""
    : "";

  return (
    <UnauthenticatedLayout>
      <div className={styles.form}>
        <FieldGroup className={styles.fields}>
          {originMessage && (
            <Typography align="left" variant="body1">
              {t(originMessage)}
            </Typography>
          )}
          {error && (
            <Typography align="left" variant="body1" className={styles.error}>
              {error}
            </Typography>
          )}
          <TextField
            label={t("Email Address")}
            onChange={e => setUsername(e.target.value)}
            onKeyPress={onKeyPress}
            variant="filled"
            InputLabelProps={{ style: { color: "white" } }}
          />
          <TextField
            inputProps={{ type: "password" }}
            label={t("Password")}
            onChange={e => setPassword(e.target.value)}
            onKeyPress={onKeyPress}
            variant="filled"
            InputLabelProps={{ style: { color: "white" } }}
          />
        </FieldGroup>
        <div className={styles.forgotPassword}>
          <Link className={styles.link} to="/request-password-reset">
            <Typography variant="body2">{t("forgot password")}</Typography>
          </Link>
        </div>
        <FieldGroup className={styles.buttonWrapper}>
          <Button color="primary" onClick={login} variant="contained">
            {t("Login")}
          </Button>
        </FieldGroup>
      </div>
    </UnauthenticatedLayout>
  );
};

export default withRouter(Login);
